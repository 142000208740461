<div mat-dialog-title fxLayout="row">
	<div fxLayout="column">
		<div class="doc-dialog-title-row">
			<h3>{{ data.title }}</h3>
			<i class="uil-copy" *ngIf="data?.path" (click)="documentationService.copyTextToClipboard(data.path)"></i>
		</div>
		<p class="fw-300 mt-4 text-spacing">
			<app-documentation-dynamic-type [text]="data.description" [formats]="data.formats"></app-documentation-dynamic-type>
		</p>
	</div>
	<div class="actions margin-left-auto">
		<button mat-flat-button color="secondary" class="button-extra-small" [mat-dialog-close]="false">Close</button>
	</div>
</div>

<div class="doc-light-container">
	<div *ngFor="let section of data.sections; first as firstElement" [ngSwitch]="section.type">
		<!-- Main Title -->
		<div class="doc-main-title" *ngSwitchCase="DocumentationFlowSectionTypeEnum.MAIN_TITLE" [class.mt-0]="firstElement">
			<app-documentation-dynamic-type [text]="section.text" [formats]="section.formats"></app-documentation-dynamic-type>
		</div>

		<!-- Title -->
		<div class="doc-container-title" *ngSwitchCase="DocumentationFlowSectionTypeEnum.TITLE" [class.mt-0]="firstElement">
			<app-documentation-dynamic-type [text]="section.text" [formats]="section.formats"></app-documentation-dynamic-type>
		</div>

		<!-- Subtitle -->
		<div class="doc-container-subtitle" *ngSwitchCase="DocumentationFlowSectionTypeEnum.SUBTITLE" [class.mt-0]="firstElement">
			<app-documentation-dynamic-type [text]="section.text" [formats]="section.formats"></app-documentation-dynamic-type>
		</div>

		<!-- Bulleted List -->
		<div *ngSwitchCase="DocumentationFlowSectionTypeEnum.BULLETED_LIST">
			<app-documentation-dynamic-type
				*ngIf="section?.text"
				[text]="section.text"
				[formats]="section.formats"
			></app-documentation-dynamic-type>
			<ul class="doc-unordered-list" *ngIf="section?.bulletedList?.li">
				<li *ngFor="let item of section.bulletedList.li">
					<app-documentation-dynamic-type [text]="item" [formats]="section.formats"></app-documentation-dynamic-type>
				</li>
			</ul>
		</div>

		<!-- Default Paragraph -->
		<p class="doc-default-paragraph text-spacing" *ngSwitchCase="DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH">
			<app-documentation-dynamic-type [text]="section.text" [formats]="section.formats"></app-documentation-dynamic-type>
		</p>

		<!-- Code Snippet -->
		<app-code-display [code]="section.text" *ngSwitchCase="DocumentationFlowSectionTypeEnum.CODE_SNIPPET"></app-code-display>

		<!-- Separators -->
		<!-- Section Separator -->
		<!-- <div class="doc-section-separator"></div> -->

		<!-- Paragraph Separator -->
		<!-- <div class="doc-paragraph-separator"></div> -->
	</div>
</div>
