import { DocumentationQABasic } from '../../../models/qa/doc-qa-basic.model';
import { DocumentationQASectionTypeEnum } from '../../../models/qa/doc-qa-types.model';

export const documentationBudgetDashboardQA: DocumentationQABasic = {
	title: 'Budget Dashboard',
	description:
		'The Budget Dashboard provides a summary of budget allocations for programs in the "activate" phase. It allows clients to view their budgets broken down by key segments such as brand, retailer, funding source, phase, initiative, and more.',
	sections: [
		{
			type: DocumentationQASectionTypeEnum.MAIN_TITLE,
			text: 'Dashboard Overview',
		},
		{
			type: DocumentationQASectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'The dashboard begins with broad, high-level widgets, which become more detailed as you navigate through each tab. The final tab shows raw budget data, offering clients flexibility to download and pivot the data for additional views if needed.',
		},
		{
			type: DocumentationQASectionTypeEnum.MAIN_TITLE,
			text: 'Dashboard Tabs',
		},
		{
			type: DocumentationQASectionTypeEnum.SUBTITLE,
			text: 'Budget Overview',
		},
		{
			type: DocumentationQASectionTypeEnum.BULLETED_LIST,
			text: 'This is the broadest client  data giving a top line of budget information.',
			bulletedList: [
				'Total Budget Allocation: Displays the total amount allocated to the budget tab for all applicable programs.',
				'Total Estimated Spend: Shows the sum of estimated spend on the Tactic Cost tab for all tactics across relevant programs.',
				'Total Invoices Paid: Displays the total amount of paid invoices across all relevant tactics.',
				'Allocated Budget by Funding Source: Breaks down the budget by the funding source field in the budget tab.',
				'Allocated Budget by Funding Source and Type: Provides an additional breakdown of the allocated budget by both funding source and funding type.',
			],
			formats: [
				{
					textIdentifier: 'Total Budget Allocation',
					styling: ['bold'],
				},
				{
					textIdentifier: 'Total Estimated Spend',
					styling: ['bold'],
				},
				{
					textIdentifier: 'Total Invoices Paid',
					styling: ['bold'],
				},
				{
					textIdentifier: 'Allocated Budget by Funding Source',
					styling: ['bold'],
				},
				{
					textIdentifier: 'Allocated Budget by Funding Source and Type',
					styling: ['bold'],
				},
			],
		},
		{
			type: DocumentationQASectionTypeEnum.SUBTITLE,
			text: 'By Brand',
		},
		{
			type: DocumentationQASectionTypeEnum.BULLETED_LIST,
			text: 'Slices budget data by brand. As you scroll down, more granular views are provided, such as:',
			bulletedList: ['Allocated Budget by Brand and Retailer', 'Allocated Budget by Funding Source or Funding Type'],
		},
		{
			type: DocumentationQASectionTypeEnum.SUBTITLE,
			text: 'By Retailer',
		},
		{
			type: DocumentationQASectionTypeEnum.BULLETED_LIST,
			text: 'Slices budget data by retailer, with further breakdowns:',
			bulletedList: ['Allocated Budget by Retailer and Brand', 'Allocated Budget by Funding Source or Type'],
		},
		{
			type: DocumentationQASectionTypeEnum.SUBTITLE,
			text: 'By Phase',
		},
		{
			type: DocumentationQASectionTypeEnum.BULLETED_LIST,
			text: 'Slices budget data by the program phase, allowing visibility into budgets still in the planning phases. This tab is useful for understanding the impact of budget cuts. Further breakdowns include:',
			bulletedList: ['Allocated Budget by Phase and Brand', 'Allocated Budget by Phase and Funding Source'],
		},
		{
			type: DocumentationQASectionTypeEnum.SUBTITLE,
			text: 'By Initiative',
		},
		{
			type: DocumentationQASectionTypeEnum.BULLETED_LIST,
			text: 'Displays allocated budget data by Brand Initiative, with additional granular views:',
			bulletedList: ['Allocated Budget by Initiative and Brand', 'Allocated Budget by Initiative and Retailer'],
		},
		{
			type: DocumentationQASectionTypeEnum.SUBTITLE,
			text: 'By Program',
		},
		{
			type: DocumentationQASectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'The most granular tab, presenting raw data in a table format. Each program, funding source, and type combination is shown in a separate line. A single program may have multiple lines depending on how many budget items are listed.',
		},
	],
	directions: [
		{
			title: 'Create a New Budget Period',
			completed: false,
		},
		{
			title: 'Create a Multi-Brand Program',
			completed: false,
			items: [
				{
					title: 'Details Tab',
					completed: false,
					items: [
						{
							title: 'Complete the fields for Brands (using two brands), Retailers, Program Type, Initiative, and Phase.',
							completed: false,
						},
					],
				},
				{
					title: 'Budget Tab',
					completed: false,
					items: [
						{
							title: 'Create two separate budget items:',
							completed: false,
							items: [
								{
									title: 'Budget Item 1: Includes both brands, including Funding Source, Funding Type, and Budget.',
									completed: false,
								},
								{
									title: 'Budget Item 2: Includes only one brand, including Funding Source, Funding Type, and Budget.',
									completed: false,
								},
							],
						},
					],
				},
				{
					title: 'Tactic Tab',
					completed: false,
					items: [
						{
							title: 'Create three tactics:',
							completed: false,
							items: [
								{
									title: 'Tactic 1: Includes both brands participating.',
									completed: false,
								},
								{
									title: 'Tactic 2: Includes only one brand participating.',
									completed: false,
								},
								{
									title: 'Tactic 3: Includes the other brand participating.',
									completed: false,
								},
								{
									title: 'Ensure each tactic’s type, cost, and cost type are completed.',
									completed: false,
								},
								{
									title: 'Ensure that the total for tactics differs from the total for the budget to verify that the dashboard uses the correct budget amounts rather than the tactic amounts.',
									completed: false,
								},
							],
						},
					],
				},
			],
		},
		{
			title: 'Refresh and Check the Dashboard',
			completed: false,
			items: [
				{
					title: 'Refresh the page, then navigate to the budget dashboard and filter for the newly created budget period.',
					completed: false,
				},
			],
		},
		{
			title: 'QA Checklist',
			completed: false,
			items: [
				{
					title: 'Confirm that the budget amounts match the amounts entered on the Budget tab.',
					completed: false,
				},
				{
					title: 'Verify that the brand breakdown is accurate.',
					completed: false,
				},
				{
					title: 'If a widget displays double the budget amount or the amount from the first budget item, check that the dashboard is using the correct data (budget amount vs. brand breakout).',
					completed: false,
				},
			],
		},
		{
			title: 'Create Additional Programs',
			completed: false,
			items: [
				{
					title: 'Return to the activate tab and create two additional programs, each with different retailers.',
					completed: false,
				},
				{
					title: 'You can duplicate the original program, but modify fields such as retailer, phase, brand initiative, and program type for each new program.',
					completed: false,
				},
				{
					title: 'Also, change the budget on each.',
					completed: false,
				},
				{
					title: 'Create one more program with only one brand.',
					completed: false,
				},
			],
		},
		{
			title: 'Recheck the Dashboard',
			completed: false,
			items: [
				{
					title: 'After making changes, verify that the dashboard updates accurately by comparing the Allocated Budget column in the table view to the totals displayed in the dashboard for each widget.',
					completed: false,
				},
				{
					title: 'Change the brand filter and retest each widget.',
					completed: false,
				},
				{
					title: 'Change the retailer filter and retest each widget.',
					completed: false,
				},
				{
					title: 'Change the budget filter and retest each filter.',
					completed: false,
				},
				{
					title: 'Test two filters together.',
					completed: false,
				},
			],
		},
	],
};
