import { Program } from './program.model';
import moment from 'moment';
import { dateDiffInDays } from '../../../_core/utils/date.utils';

export class ProgramUtils {
	/**
	 * Get the unique funding types for a program based on funding source / type combo.
	 * @returns
	 */
	public static getUniqueFundingTypes(program: Program): string[] {
		const fundingSources: string[] = [];
		if (program.budgetAllocations?.length) {
			for (const budgetAllocation of program.budgetAllocations) {
				fundingSources.push(`${budgetAllocation.fundingSource.name} - ${budgetAllocation.fundingType.name}`);
			}
		}
		return [...new Set(fundingSources)];
	}

	public static programCompletionPercentage(
		start: Date,
		end: Date,
		current: Date,
		unit: 'daily' | 'weekly' | 'monthly' = 'daily'
	): number {
		let totalUnits, completedUnits;

		if (unit === 'daily') {
			return dateDiffInDays(start, current) / dateDiffInDays(start, end);
		} else if (unit === 'weekly') {
			// Set all dates to the start of their respective weeks (Sunday)
			const startDate = moment(start).startOf('week').day(0);
			const endDate = moment(end).startOf('week').day(0);
			const currentDate = moment(current).startOf('week').day(0);

			// Calculate the total number of weeks in the program
			totalUnits = endDate.diff(startDate, 'weeks') + 1;

			// Calculate the number of completed weeks based on the current date
			completedUnits = currentDate.diff(startDate, 'weeks') + 1;
		} else if (unit === 'monthly') {
			// Set all dates to the start of their respective months
			const startDate = moment(start).startOf('month');
			const endDate = moment(end).startOf('month');
			const currentDate = moment(current).startOf('month');

			// Calculate the total number of months in the program
			totalUnits = endDate.diff(startDate, 'months') + 1;

			// Calculate the number of completed months based on the current date
			completedUnits = currentDate.diff(startDate, 'months') + 1;
		} else {
			throw new Error('Invalid unit. Please use "weeks" or "months".');
		}
		return completedUnits / totalUnits;
	}
}
