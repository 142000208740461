import { DocumentationComponent } from '../../models/dev/doc-component.model';
import { DocumentationFlowTypeEnum } from '../../models/dev/doc-types.enum';

export const documentationTableColumnDialogComponent: DocumentationComponent = {
	title: 'TableColumnDialogComponent',
	path: 'web/src/app/components/table-column-dialog/table-column-dialog.component.ts',
	description:
		'The Table Column Dialog is a popup component, that allows users to select and configure columns for display in table or calendar views. It serves as the primary means for users to interact with and customize the column system. Inputs and outputs of this component are provided though the MatDialog service.',
	inputs: {
		items: [
			{
				name: 'columns',
				type: 'Column[]',
				comment: 'The list of all available columns',
			},
			{
				name: 'selectedColumns',
				type: 'Column[]',
				comment: 'The list of currently selected columns',
			},
			{
				name: 'entities',
				type: 'string[]',
				comment: 'The list of entity types for the current context',
			},
			{
				name: 'maxNumberOfActiveColumns',
				type: 'number',
				comment: 'The maximum number of active columns allowed',
			},
		],
	},
	outputs: {
		items: [
			{
				name: 'onSave',
				type: 'Column[]',
				comment: 'Outputs the list of selected columns when the user saves the changes',
			},
		],
	},
	properties: {
		items: [
			{
				name: 'filter',
				type: 'string',
				value: '""',
				comment: 'The current filter value',
			},
			{
				name: 'filteredColumns',
				type: 'Column[]',
				value: '[]',
				comment: 'The list of columns that match the current filter',
			},
			{
				name: 'selectedColumns',
				type: 'Column[]',
				value: '[]',
				comment: 'The list of currently selected columns',
			},
			{
				name: 'categoryFilterValue',
				type: 'Entity',
				comment: 'The currently selected category filter',
			},
			{
				name: 'categoryOptions',
				type: 'Entity[]',
				value: '[]',
				comment: 'The list of available category filter options',
			},
			{
				name: 'entitySettings',
				type: 'EntitySettings',
				comment: 'The entity settings for the current context',
			},
			{
				name: 'private measurementsColumns',
				type: 'Column[]',
				value: '[]',
				comment: 'The list of measurement columns',
			},
			{
				name: 'private selectedMeasurementColumns',
				type: 'Column[]',
				value: '[]',
				comment: 'The list of currently selected measurement columns',
			},
			{
				name: 'defaultSelectedMeasurementTypes',
				type: 'MeasurementAggregateType[]',
				value: '[]',
				comment: 'The default selected measurement types',
			},
		],
	},
	flows: [DocumentationFlowTypeEnum.COLUMNS],
	methods: [
		{
			name: 'ngOnInit(): void',
			code: `
/**
 * Initializes the component by filtering the columns based on the selected entities, sorting the external ID and measurement columns, and initializing the default measurement types.
 */
`,
		},
		{
			name: 'getMaskedName(column: Column): string',
			code: `
/**
 * Returns the masked name of the column if a mask path is provided, otherwise returns the original column name.
 * @param column The column object.
 * @returns The masked name of the column or the original column name.
 */
`,
		},
		{
			name: 'getMatchingColumns(column: Column): Column[]',
			code: `
/**
 * Returns an array of columns that match the given column, taking into account that
 * measurement columns come in pairs of actual and benchmarked columns.
 * @param column The column to find matching columns for.
 * @returns An array of columns that match the given column.
 */
`,
		},
		{
			name: 'isBenchmarkColumn(column: Column): boolean',
			code: `
/**
 * Checks if a benchmark column is selected, given an actual column.
 * Will return true if the benchmark column of the given actual column is selected.
 * @param column The actual column to check
 * @returns True if the benchmark column is selected, false otherwise
 */
`,
		},
		{
			name: 'onSelectColumn($event: MatCheckboxChange, column: Column): void',
			code: `
/**
 * Handles the selection of a column in the table column dialog.
 * Will either select or deselect all columns in the category if the "all" checkbox is selected,
 * or select or deselect the individual column if the regular checkbox is selected.
 * Will also update the selected columns list by adding or removing the selected column.
 * @param $event The MatCheckboxChange event.
 * @param column The column that was selected or deselected.
 */
`,
		},
		{
			name: '_handleAllCheckbox(allColumn: Column, isChecked: boolean): void',
			code: `
/**
 * Handles the selection of the "all" checkbox for a given category.
 * Selects or deselects all columns in the category based on the checkbox state.
 * @param allColumn The column object for the "all" checkbox that was selected/deselected.
 * @param isChecked The state of the checkbox.
 */
`,
		},
		{
			name: '_handleIndividualCheckbox(column: Column, isChecked: boolean): void',
			code: `
/**
 * Handles the selection of an individual column.
 * Selects or deselects the column based on the checkbox state.
 * If the column is a measurement column, this function will only filter out the
 * benchmark or actual column.
 * @param column The column object that was selected/deselected.
 * @param isChecked The state of the checkbox.
 */
`,
		},
		{
			name: '_updateAllCheckboxState(category: string): void',
			code: `
/**
 * Updates the state of the "all" checkbox for the given category.
 * Will add or remove the "all" column from the selected columns list.
 * @param category The category of columns to update.
 */
`,
		},
		{
			name: '_getCategoryColumnCount(category: string): number',
			code: `
/**
 * Returns the number of columns in the given category
 * @param category The category of columns to count.
 * @returns The number of columns in the category.
 */
`,
		},
		{
			name: 'onApplyFilter($event): void',
			code: `
/**
 * Handles the application of a filter to the columns.
 * @param $event The event object containing the filter value.
 */
`,
		},
		{
			name: 'queryFilter(column: Column, filter: string): boolean',
			code: `
/**
 * Checks if a column matches the given filter.
 * @param column The column to check.
 * @param filter The filter string.
 * @returns True if the column matches the filter, false otherwise.
 */
`,
		},
		{
			name: 'categoryFilter(column: Column, categoryFilterValue: Entity): boolean',
			code: `
/**
 * Checks if a column belongs to the selected category.
 * @param column The column to check.
 * @param categoryFilterValue The selected category.
 * @returns True if the column belongs to the selected category, false otherwise.
 */
`,
		},
		{
			name: 'groupByCategory(columns: Column[]): { [key: string]: Column[] }',
			code: `
/**
 * Groups the columns by their category.
 * @param columns The columns to group.
 * @returns An object where the keys are the column categories and the values are arrays of columns.
 */
`,
		},
		{
			name: 'onSave(): void',
			code: `
/**
 * Handles the saving of the selected columns.
 * Checks if the number of selected columns exceeds the maximum allowed and displays a warning message if so.
 * Closes the dialog with the selected columns.
 */
`,
		},
		{
			name: 'onSelectMeasurementType($event: MatSlideToggleChange, type: MeasurementAggregateType = MeasurementAggregateType.Actual): void',
			code: `
/**
 * Handles the selection of a measurement type in the dialog.
 * Updates the list of selected columns based on the selected measurement types.
 * @param $event The MatSlideToggleChange event.
 * @param type The measurement type that was selected or deselected.
 */
`,
		},
		{
			name: '_getMeasurementMainPath(path: string): string',
			code: `
/**
 * Extracts the main path from a measurement column ID.
 * @param path The measurement column ID.
 * @returns The main path of the measurement column.
 */
`,
		},
		{
			name: '_upsertMeasurementColumns(): Column[]',
			code: `
/**
 * Updates the list of selected columns to include the appropriate measurement columns based on the selected measurement types.
 * @returns The updated list of selected columns.
 */
`,
		},
		{
			name: 'isNestedColumn(column: Column, allIds: string[], columnId: string): boolean',
			code: `
/**
 * Checks if a column is a nested column (i.e., part of the "all" column group) based on the provided IDs.
 * @param column The column to check.
 * @param allIds The IDs of the "all" columns.
 * @param columnId The ID of the column being checked.
 * @returns True if the column is a nested column, false otherwise.
 */
`,
		},
		{
			name: 'isChecked(column: Column, category: string, selectedColumns: Column[], allColumnIds: string): boolean',
			code: `
/**
 * Checks if a column is checked in the selected columns list.
 * @param column The column to check.
 * @param category The category of the column.
 * @param selectedColumns The list of selected columns.
 * @param allColumnIds The IDs of the "all" columns.
 * @returns True if the column is checked, false otherwise.
 */
`,
		},
		{
			name: 'isIndeterminateChecked(column: Column, category: string, allColumnIds: string[], selectedColumns: Column[]): boolean',
			code: `
/**
 * Checks if a column's checkbox should be in an indeterminate state.
 * @param column The column to check.
 * @param category The category of the column.
 * @param allColumnIds The IDs of the "all" columns.
 * @param selectedColumns The list of selected columns.
 * @returns True if the column's checkbox should be indeterminate, false otherwise.
 */
`,
		},
	],
};
