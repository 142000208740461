export type DocumentationTypeOption = DocumentationInterfaceTypeEnum | DocumentationFlowTypeEnum | DocumentationComponentTypeEnum;

// Interface Types
export enum DocumentationInterfaceTypeEnum {
	FILTER = 'Filter', // value has to be readable
	FILTER_CATEGORY = 'FilterCategory',
}

// Flow Types
export enum DocumentationFlowTypeEnum {
	FILTERS = 'Filters', // value has to be readable
	REPORTING_DASHBOARD = 'ReportingDashboard',
	COLUMNS = 'Columns',
	NEW_GRAPH = 'AddNewGraph',
}

// Component Types
export enum DocumentationComponentTypeEnum {
	ADMIN_COMPONENT = 'AdminComponent',
	TABLE_COLUMN_DIALOG = 'TableColumnDialogComponent',
}
