<div mat-dialog-title fxLayout="row">
	<div fxLayout="column">
		<h3>Q&A Overviews</h3>
		<p class="fw-300 mt-4">
			All the key Q&A for navigating and using the documentation, providing a clear overview of each process and interaction
		</p>
	</div>
	<div class="actions margin-left-auto">
		<button mat-flat-button color="secondary" class="button-extra-small" [mat-dialog-close]="false">Close</button>
	</div>
</div>

<app-documentation-search (changed)="searchTerm = $event"></app-documentation-search>

<div class="qa-list-container">
	<div class="qa-list-card" *ngFor="let qa of qas | docSearchFilter: searchTerm" (click)="onQAPressed(qa.key)">
		<h3>{{ qa.title }}</h3>
		<p class="qa-list-description">{{ qa.description }}</p>
	</div>
</div>
