import { Component, Input, AfterViewInit, OnChanges, ViewChild, ElementRef, AfterViewChecked, OnInit } from '@angular/core';
import { HighlightService } from '../../../services/prism.service';

@Component({
	selector: 'app-code-display',
	templateUrl: './code-display.component.html',
	styleUrls: ['./code-display.component.scss'],
})
export class CodeDisplayComponent implements OnInit, AfterViewChecked {
	@Input() code: string;
	@Input() useExpansionPanel: boolean = false;
	@Input() methodName?: string;

	highlighted = false;
	show: boolean = false;
	language: string = 'typescript';

	constructor(private highlightService: HighlightService) {}

	ngOnInit(): void {
		// if starts with { and ends with } then it is an object and the language should be changed to javascript
		if (this.code.trim().startsWith('{') && this.code.trim().endsWith('}')) {
			this.language = 'javascript';
		}
	}

	ngAfterViewChecked(): void {
		if (!this.highlighted) {
			this.highlightService.highlightAll();
			this.highlighted = true;
		}
	}

	toggleShow(): void {
		this.show = !this.show;
	}

	trim(code: string): string {
		return code.trim();
	}
}
