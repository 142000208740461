import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DocumentationService } from '../../../services/documentation.service';
import { DocumentationComponent } from '../../../models/dev/doc-component.model';
import { DocumentationFlowSectionFormatTypeEnum, DocumentationFlowSectionFormat } from '../../../models/dev/doc-overview.model';
import { DocumentationDecorator } from '../../../models/dev/doc-decorator.model';
import { DocumentationFlowTypeEnum, DocumentationInterfaceTypeEnum } from '../../../models/dev/doc-types.enum';

interface DecoratorObject {
	title: string;
	decorators: {
		items: DocumentationDecorator[];
		formats?: DocumentationFlowSectionFormat[];
	};
	type: 'inputs' | 'outputs' | 'properties';
}

@Component({
	selector: 'app-documentation-component-overview',
	templateUrl: './component-overview.component.html',
	styleUrls: ['./component-overview.component.scss']
})
export class DocumentationComponentOverview implements OnInit {
	@Input() data: DocumentationComponent;

	decorators: DecoratorObject[] = [];

	constructor(
		@Inject(MAT_DIALOG_DATA) public dialogData: DocumentationComponent,
		public readonly documentationService: DocumentationService
	) {}

	ngOnInit(): void {
		this.data = this.data || this.dialogData;
		this._setDecorators();
	}

	onFlowPressed(name: DocumentationFlowTypeEnum): void {
		this.documentationService.openDataDialog(name, DocumentationFlowSectionFormatTypeEnum.FLOW);
	}

	onModelPressed(name: DocumentationInterfaceTypeEnum): void {
		this.documentationService.openDataDialog(name, DocumentationFlowSectionFormatTypeEnum.INTERFACE);
	}

	private _setDecorators(): void {
		const decorators: DecoratorObject[] = [];
		if (this.data.inputs) {
			decorators.push({ title: 'Important Input Decorators', decorators: this.data.inputs, type: 'inputs' });
		}
		if (this.data.outputs) {
			decorators.push({ title: 'Important Output Decorators', decorators: this.data.outputs, type: 'outputs' });
		}
		if (this.data.properties) {
			decorators.push({ title: 'Important Properties', decorators: this.data.properties, type: 'properties' });
		}
		this.decorators = decorators;
	}
}
