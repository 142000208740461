import {
	DocumentationFlow,
	DocumentationFlowSectionFormatTypeEnum,
	DocumentationFlowSectionTypeEnum,
} from '../../models/dev/doc-overview.model';
import { DocumentationInterfaceTypeEnum } from '../../models/dev/doc-types.enum';

export const documentationFiltersOverview: DocumentationFlow = {
	title: 'Filters Mechanism',
	description:
		'The filter mechanism is essential for configuring and categorizing filters across various levels in the application. Filters are organized into specific collections, each associated with a corresponding model file.',
	sections: [
		{
			type: DocumentationFlowSectionTypeEnum.MAIN_TITLE,
			text: 'Filter Definition Files',
		},
		{
			type: DocumentationFlowSectionTypeEnum.BULLETED_LIST,
			text: 'Filter Definition Files',
			bulletedList: {
				li: [
					'OverallFilterCollection: The primary file containing filters for the overall view',
					'ProgramFilterCollection',
					'PlanFilterCollection',
					'TacticFilterCollection: Contains filters for the tactic view',
				],
			},
			formats: [
				/* {
					textIdentifier: DocumentationInterfaceTypeEnum.,
					type: DocumentationFlowSectionFormatTypeEnum.INTERFACE
				} */
			],
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: "In these files, filters are defined as an array of Filter objects. Each filter has a unique id and name property, along with other properties that define the filter's behavior and appearance.",
		},
		{
			type: DocumentationFlowSectionTypeEnum.MAIN_TITLE,
			text: 'Filter Configuration',
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: `Filters are defined using the ${DocumentationInterfaceTypeEnum.FILTER} interface, which includes the following properties:`,
			formats: [
				{
					textIdentifier: DocumentationInterfaceTypeEnum.FILTER,
					type: DocumentationFlowSectionFormatTypeEnum.INTERFACE,
				},
			],
		},
		{
			type: DocumentationFlowSectionTypeEnum.CODE_SNIPPET,
			text: `
export interface Filter {
	id: string;
	name: string;
	//... other properties, please check the interface for more details
}
`,
		},
		{
			type: DocumentationFlowSectionTypeEnum.TITLE,
			text: 'Filter Group Types',
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'Filters in the filter bar are categorized into distinct groups. These groups are defined as follows:',
		},
		{
			type: DocumentationFlowSectionTypeEnum.CODE_SNIPPET,
			text: `
			export const FilterGroupTypeDisplay: { [key in FilterGroupType]: string } = {
  [FilterGroupType.displayGroup]: 'Display Settings',
  [FilterGroupType.plan]: 'Plan Level',
  [FilterGroupType.program]: 'Program Level',
  [FilterGroupType.plannedProgram]: 'Planned Program Level',
  [FilterGroupType.tactic]: 'Tactic Level',
  [FilterGroupType.additional]: 'Additional Filters',
};
			`,
		},
		{
			type: DocumentationFlowSectionTypeEnum.MAIN_TITLE,
			text: 'Filter Visibility',
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'Every filter has a visibility property, which determines whether the filter is visible or hidden. Visibility can be configured in visibilityCondition property. </br>Example filter visibility condition:',
		},
		{
			type: DocumentationFlowSectionTypeEnum.CODE_SNIPPET,
			text: `
			visibilityConditions: {
\t\t\t\toperator: LogicalConjunction.OR,
\t\t\t\tfilterConditions: [
\t\t\t\t\t{
\t\t\t\t\t\tpath: 'section',
\t\t\t\t\t\tcondition: Condition.EQ,
\t\t\t\t\t\tvalue: 'activation'
\t\t\t\t\t},
\t\t\t\t\t{
\t\t\t\t\t\tpath: 'section',
\t\t\t\t\t\tcondition: Condition.EQ,
\t\t\t\t\t\tvalue: 'media-plan'
\t\t\t\t\t}
\t\t\t\t]
\t\t\t};
			`,
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: '</br>Visibility conditions are then evaluated in doesFilterPassVisibilityCondition method inside filter.query.ts.',
			formats: [
				{
					textIdentifier: 'doesFilterPassVisibilityCondition',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
				{ textIdentifier: 'filter.query.ts', type: DocumentationFlowSectionFormatTypeEnum.TEXT, classes: ['file-location'] },
			],
		},
		{
			type: DocumentationFlowSectionTypeEnum.MAIN_TITLE,
			text: 'Filter List Config',
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'FilterListConfig is additional configuration to for additional exclude or include filters.',
			formats: [
				{
					textIdentifier: 'FilterListConfig',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
			],
		},
		{
			type: DocumentationFlowSectionTypeEnum.MAIN_TITLE,
			text: 'Inline Filters',
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'Filter bar also includes inline filters, left and right. In order to add filter to inline you need to add filter id to this file default-activate-inline-filters.data.ts. This is for Activate page. Order of inline filters is defined in filter order property.',
			formats: [
				{
					textIdentifier: 'default-activate-inline-filters.data.ts',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
			],
		},
		{
			type: DocumentationFlowSectionTypeEnum.CODE_SNIPPET,
			text: `
			export const defaultActivateInlineFilters = {
  left: {
    includeIds: ['overall-budget-period-multiselect', 'program-group-by', 'overall-brands', 'overall-retailers', 'overall-program-phases'],
  },
};
			`,
		},
		{
			type: DocumentationFlowSectionTypeEnum.MAIN_TITLE,
			text: 'Filter UI components',
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'Every filter can have different types, property type in filter.model.ts. Types are defined in FilterType enum. Here is the list of available types:',
			formats: [
				{
					textIdentifier: 'filter.model.ts',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
				{
					textIdentifier: 'FilterType',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
			],
		},
		{
			type: DocumentationFlowSectionTypeEnum.CODE_SNIPPET,
			text: `
			export type FilterType =
\t| 'single-select'
\t| 'multi-select'
\t| 'search'
\t| 'toggle-multi-select'
\t| 'toggle'
\t| 'date-range'
\t| 'toggle-chip'
\t| 'single-select-with-groups'
\t| 'multi-select-with-search'
\t| 'text'
\t| 'textarea'
\t| 'percentage'
\t| 'button-select'
\t| 'date';
			`,
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text:
				'</br>In order to use filters on UI you need to use <app-filter-input> component. This component is responsible for rendering filter bar (selection menu with filters + inline filters + reset button).\n' +
				'List of filters is rendered in sub component <app-filter-list>. This component is responsible for rendering filters based on their type. Every filter type has its own component <app-filter-input>.',
			formats: [
				{
					textIdentifier: '<app-filter-input>',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
				{
					textIdentifier: '<app-filter-list>',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
			],
		},
		{
			type: DocumentationFlowSectionTypeEnum.MAIN_TITLE,
			text: 'Filter dropdown options',
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'Some filters have dynamic options (FilterOption[]), these filters can be set from global settings here setFilterOptionsFromSettings or manually for every filter setFilterOptions',
			formats: [
				{
					textIdentifier: 'FilterOption[]',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
				{
					textIdentifier: 'setFilterOptionsFromSettings',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
				{
					textIdentifier: 'setFilterOptions',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
			],
		},

		{
			type: DocumentationFlowSectionTypeEnum.MAIN_TITLE,
			text: 'Predefined Filters in Admin Settings',
		},
		{
			type: DocumentationFlowSectionTypeEnum.TITLE,
			text: 'Inline Filters',
		},
		{
			type: DocumentationFlowSectionTypeEnum.BULLETED_LIST,
			text: 'Inline filters are predefined filters that are displayed in the filter bar. These filters are saved in Organization Settings, they are organized based on app sections. Filters are defined by their unique Id.',
			bulletedList: { li: ['Activate Inline Filters', 'Plan Inline Filters', 'Media Plan Inline Filters'] },
		},
		{
			type: DocumentationFlowSectionTypeEnum.TITLE,
			text: 'Default Filters for Activate Page',
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'Activate page has defined their own View Settings. In Organization Settings, you can define the default view for the Activate page.',
		},
		{
			type: DocumentationFlowSectionTypeEnum.TITLE,
			text: 'Reporting Filter Settings',
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'Filters for Reporting page are saved in Reporting Settings section. To modify default reporting filters you need to update defaultReportingConfigMap.data.ts file. Related filters are defined in these fields, please check CustomDashboardPageConfig interface for more details.',
			formats: [
				{
					textIdentifier: 'defaultReportingConfigMap.data.ts',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
				{
					textIdentifier: 'CustomDashboardPageConfig',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
			],
		},
		{
			type: DocumentationFlowSectionTypeEnum.CODE_SNIPPET,
			text: `
			{
    filters: FilterListConfig;
    inlineFilters: InlineFilterListConfig;
    filterParamOverrides: FilterParameters;
}
			`,
		},
		{
			type: DocumentationFlowSectionTypeEnum.TITLE,
			text: 'Quick Views',
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'Quick views are predefined filter configurations that can be applied to the filter bar. These views are saved and can be accessed from the filter bar.',
		},
	],
};
