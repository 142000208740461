import { BrowserModule, Title } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { environment } from '../environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { GeometryDesignSystemLibModule } from '@nudgelabs/geometry-design-system-lib';
import { RequestInterceptor } from './_core/interceptors/request.interceptor';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { OKTA_CONFIG } from '@okta/okta-angular';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { PendingChangesGuard } from './_core/guards/pending-changes.guard';
import { CacheInterceptor } from './_core/interceptors/cache.interceptor';
import { MegaGraphInterceptor } from './_core/interceptors/mega-graph.interceptor';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import {
	MatMomentDateModule,
	MomentDateAdapter,
	MAT_MOMENT_DATE_FORMATS,
	MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { NoPageComponent } from './pages/no-page/no-page.component';
import { DocumentationModule } from './documentation/documentation.module';

// Initial Okta configuration
const config = {
	// 00MG0VT3RoBlMsqeEPjA8fpEjO6NZQMdin9gCIf_Ux
	clientId: `0oak0g96jT3KDH6wO5d6`,
	issuer: `https://dev-32618356.okta.com/oauth2/default`,
	redirectUri: 'http://localhost:4200/sso/okta/374cf1cf-7a9a-41b6-a5ea-cb160582e8c53/login',
	scopes: ['openid', 'profile', 'email'],
	pkce: true,
	testing: {
		disableHttpsCheck: `true`,
	},
};

@NgModule({
	declarations: [AppComponent, NoPageComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		SharedModule,
		BrowserAnimationsModule,
		AkitaNgRouterStoreModule,
		HttpClientModule,
		GeometryDesignSystemLibModule,
		MatProgressSpinnerModule,
		AngularMultiSelectModule,
		DocumentationModule
		//environment.production ? [] : AkitaNgDevtools.forRoot()
	],
	providers: [
		// Turning on UTC property for the datepicker component:
		{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
		},
		{ provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
		Title,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: RequestInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MegaGraphInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: CacheInterceptor,
			multi: true,
		},
		// Set up Sentry to catch errors
		environment.production
			? []
			: {
				provide: ErrorHandler,
				useValue: Sentry.createErrorHandler({
					showDialog: true,
				}),
			},
		// Set up Sentry Trace Service
		environment.production
			? []
			: {
				provide: Sentry.TraceService,
				deps: [Router],
				useValue: undefined,
			},
		// Force Trace service to start
		environment.production
			? []
			: {
				provide: APP_INITIALIZER,
				useFactory: () => () => { },
				deps: [Sentry.TraceService],
				multi: true,
			},
		{ provide: OKTA_CONFIG, useValue: config },
		PendingChangesGuard,
	],
	bootstrap: [AppComponent],
})
export class AppModule { }
