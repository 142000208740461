import { DocumentationRelativePath } from './doc-relative-path.model';
import { DocumentationTypeOption } from './doc-types.enum';

// Custom Section Types
export interface DocumentationFlowSectionBulletedList {
	li: string[];
}

// Format
export enum DocumentationFlowSectionFormatTypeEnum {
	INTERFACE = 'INTERFACE',
	COMPONENT = 'COMPONENT',
	FLOW = 'FLOW',
	TEXT = 'TEXT',
}

export interface DocumentationFlowSectionFormat {
	textIdentifier: string | DocumentationTypeOption;
	type: DocumentationFlowSectionFormatTypeEnum;
	styling?: Array<'bold' | 'italic'>;
	classes?: Array<'file-location'>;
}

// Section
export enum DocumentationFlowSectionTypeEnum {
	MAIN_TITLE = 'MAIN_TITLE',
	TITLE = 'TITLE',
	SUBTITLE = 'SUBTITLE',

	BULLETED_LIST = 'BULLETED_LIST',
	DEFAULT_PARAGRAPH = 'DEFAULT_PARAGRAPH',

	CODE_SNIPPET = 'CODE_SNIPPET', // use code in backticks `` for easier parsing
}

export interface DocumentationFlowSection {
	type: DocumentationFlowSectionTypeEnum;
	// Optional Fields
	text?: string;
	formats?: DocumentationFlowSectionFormat[];
	bulletedList?: DocumentationFlowSectionBulletedList; // has to be added if type is BULLETED_LIST
}

// Overview
export interface DocumentationFlow {
	title: string;
	path?: DocumentationRelativePath; // if overview has main component
	description: string;
	formats?: DocumentationFlowSectionFormat[];
	sections: DocumentationFlowSection[];
}
