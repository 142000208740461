<div mat-dialog-title fxLayout="row" *ngIf="!embedded">
	<div fxLayout="column">
		<h3>Flows Overview</h3>
		<p class="fw-300 mt-4">
			All the key flows for navigating and using the documentation, providing a clear overview of each process and interaction
		</p>
	</div>
	<div class="actions margin-left-auto">
		<button mat-flat-button color="secondary" class="button-extra-small" [mat-dialog-close]="false">Close</button>
	</div>
</div>

<app-documentation-search (changed)="searchTerm = $event" *ngIf="!embedded"></app-documentation-search>

<div class="flow-container">
	<div class="flow-card" *ngFor="let flow of flows | docSearchFilter: searchTerm" (click)="onFlowPressed(flow.key)">
		<h3>{{ flow.title }}</h3>
		<p class="flow-description">{{ flow.description }}</p>
	</div>
</div>
