import { Component, Inject, Input, OnInit } from '@angular/core';
import { DocumentationService } from '../../../services/documentation.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentationFlow, DocumentationFlowSectionTypeEnum } from '../../../models/dev/doc-overview.model';

@Component({
	selector: 'app-documentation-flow-overview',
	templateUrl: 'flow-overview.component.html',
	styleUrls: ['flow-overview.component.scss']
})
export class DocumentationFlowOverviewComponent implements OnInit {
	@Input() data: DocumentationFlow;

	public readonly DocumentationFlowSectionTypeEnum = DocumentationFlowSectionTypeEnum;

	constructor(
		@Inject(MAT_DIALOG_DATA) public dialogData: DocumentationFlow,
		public readonly documentationService: DocumentationService
	) {}

	ngOnInit(): void {
		this.data = this.data || this.dialogData;
	}
}
