import { max } from 'class-validator';
import { AggregateFunction, Condition, LogicalConjunction } from '../../../../../../api/src/_core/models/math-operations';
import { CustomDashboardConfig } from '../graph/graph.model';
import { Metadata } from '../metadata/metadata.model';

export const ProgramMetadataSettings = {
	maxCount: 6,
	defaultIds: ['budget-period', 'retailer', 'brands', 'program-phase'],
};

export const AvailableProgramMetadata: Metadata[] = [
	{
		id: 'budget-period',
		name: 'Budget Period',
		path: 'budgetPeriod',
		valueType: 'entityName',
		filter: {
			path: 'type',
			condition: Condition.EQ,
			value: 'Program',
		},
		extra: {
			maskPath: 'program.budgetPeriod.mask',
		},
	},
	{
		id: 'retailer',
		name: 'Retailer',
		path: 'retailer',
		valueType: 'entityName',
		filter: {
			path: 'type',
			condition: Condition.EQ,
			value: 'Program',
		},
		extra: {
			maskPath: 'program.retailer.mask',
		},
		visibilityConditions: {
			operator: LogicalConjunction.AND,
			filterConditions: [
				{
					path: 'settings.entities.program.retailer.disabled',
					condition: Condition.NEQ,
					value: true,
				},
			],
		},
	},
	{
		id: 'agency',
		name: 'Agency',
		path: 'agency',
		valueType: 'entityName',
		filter: {
			path: 'type',
			condition: Condition.EQ,
			value: 'Program',
		},
		extra: {
			maskPath: 'program.agency.mask',
		},
		visibilityConditions: {
			operator: LogicalConjunction.AND,
			filterConditions: [
				{
					path: 'settings.entities.program.agency.disabled',
					condition: Condition.NEQ,
					value: true,
				},
			],
		},
	},
	{
		id: 'brands',
		name: 'Brands',
		path: 'brands',
		valueType: 'field',
		filter: {
			path: 'type',
			condition: Condition.EQ,
			value: 'Program',
		},
		aggregate: {
			path: 'name',
			function: AggregateFunction.Join, // NOTE: This only supports join right now
		},
		extra: {
			maskPath: 'program.brand.mask',
		},
	},
	{
		id: 'owners',
		name: 'Owners',
		path: 'owners',
		valueType: 'field',
		filter: {
			path: 'type',
			condition: Condition.EQ,
			value: 'Program',
		},
		aggregate: {
			path: 'name',
			function: AggregateFunction.Join, // NOTE: This only supports join right now
		},
	},
	{
		id: 'program-phase',
		name: 'Program Phase',
		path: 'programPhase',
		valueType: 'badge',
		filter: {
			path: 'type',
			condition: Condition.EQ,
			value: 'Program',
		},
		extra: {
			settingsEntity: 'programPhases',
		},
	},
	{
		id: 'program-type',
		name: 'Program Type',
		path: 'programType',
		valueType: 'entityName',
		filter: {
			path: 'type',
			condition: Condition.EQ,
			value: 'Program',
		},
		extra: {
			maskPath: 'program.programType.mask',
		},
		visibilityConditions: {
			operator: LogicalConjunction.AND,
			filterConditions: [
				{
					path: 'settings.entities.program.programType.disabled',
					condition: Condition.NEQ,
					value: true,
				},
			],
		},
	},
	{
		id: 'program-sector',
		name: 'Program Sector',
		path: 'programSector',
		valueType: 'entityName',
		filter: {
			path: 'type',
			condition: Condition.EQ,
			value: 'Program',
		},
		extra: {
			maskPath: 'program.programSector.mask',
		},
	},
	{
		id: 'brand-initiative',
		name: 'Brand Initiative',
		path: 'brandInitiative',
		valueType: 'entityName',
		filter: {
			path: 'type',
			condition: Condition.EQ,
			value: 'Program',
		},
		extra: {
			maskPath: 'program.brandInitiative.mask',
		},
		visibilityConditions: {
			operator: LogicalConjunction.AND,
			filterConditions: [
				{
					path: 'settings.entities.program.brandInitiative.disabled',
					condition: Condition.NEQ,
					value: true,
				},
			],
		},
	},
];

export const programSnapshotConfig: CustomDashboardConfig = {
	id: 'program-snapshot',
	hidePanelNavigation: true,
	hideTitle: true,
	pages: [
		{
			id: 'overview',
			name: 'Overview',
			exportable: true,
			graphs: [
				{
					name: 'Description',
					type: 'program-description',
					width: '12',
				},
				{
					name: 'Objectives',
					type: 'program-objectives',
				},
				// {
				// 	name: 'Program Metrics',
				// 	type: 'program-measurements'
				// },
				{
					name: 'Budget Overview',
					type: 'program-budget-overview',
					widthBreakpoints: {
						xs: '12',
						sm: '12',
						md: '12',
						lg: '12',
					},
				},
				{
					name: 'Breakout of Funding Sources',
					type: 'app-program-funding-source-breakout',
					widthBreakpoints: {
						xs: '12',
						sm: '6',
						md: '6',
						lg: '6',
					},
				},
				{
					name: 'Program Flights',
					type: 'program-flight-calendar',
					filterParamOverrides: {
						include: {
							id: 'programs',
							name: 'Programs',
							value: {
								endpoint: 'programs',
								include: [],
							},
						},
						columnSelect: ['budgetCache', 'budgetAllocations', 'brands'],
						includeProgramsFromDraftPlans: true,
					},
					options: {
						autoHeight: false,
						items: {
							showBudgetDistributions: true,
						},
						toolbar: {
							columns: false,
						},
					},
				},
				{
					name: 'Flights Breakout',
					type: 'program-budget-allocation-distributions',
				},
				{
					name: 'Tactic Calendar',
					type: 'program-snapshot-calendar',
					options: {
						autoHeight: false,
					},
				},
				{
					name: 'Program Metrics',
					type: 'app-program-metrics-chart',
					width: '12',
					widthBreakpoints: {
						xs: '12',
						sm: '12',
						md: '12',
						lg: '12',
					},
				},
				{
					name: 'Tactic Performance',
					type: 'program-tactic-performance',
					width: '12',
					widthBreakpoints: {
						xs: '12',
						sm: '12',
						md: '12',
						lg: '12',
					},
					showAnnotation: true,
				},
				{
					name: 'Brand Breakout',
					type: 'app-program-brand-breakout',
					width: '12',
					widthBreakpoints: {
						xs: '12',
						sm: '6',
						md: '6',
						lg: '6',
					},
				},
				{
					name: 'Budget by Tactic',
					type: 'app-program-budget-by-tactic',
					width: '12',
					widthBreakpoints: {
						xs: '12',
						sm: '6',
						md: '6',
						lg: '6',
					},
				},
				{
					name: 'Spend by PESO',
					type: 'app-program-spend-by-peso',
					width: '12',
					widthBreakpoints: {
						xs: '12',
						sm: '6',
						md: '6',
						lg: '6',
					},
				},
				{
					name: 'Initiative spend',
					type: 'app-program-initiative-spend',
					width: '12',
					widthBreakpoints: {
						xs: '12',
						sm: '12',
						md: '12',
						lg: '6',
					},
				},
				{
					name: 'Vendor spend',
					type: 'app-program-vendor-spend',
					width: '12',
					widthBreakpoints: {
						xs: '12',
						sm: '6',
						md: '6',
						lg: '6',
					},
				},
				{
					name: 'Creative',
					type: 'app-program-snapshot-creative',
					width: '12',
					widthBreakpoints: {
						xs: '12',
						sm: '12',
						md: '12',
						lg: '12',
					},
				},
			],
		},
	],
};
