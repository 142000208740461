import { formatPercent } from '@angular/common';

export function getNumberWithMask(maskType: string, value: any, currencySymbol?: string, locale?: string) {
	if (!value && value !== 0) return undefined;

	switch (maskType) {
		case 'currency':
			return (currencySymbol || '$') + getShortNumber(value, 2);

		case 'integer':
			return getShortNumber(value, 2);

		case 'float':
			return formatPercent(Number(value) / 100, locale || 'en-US', '1.0-2');
	}
}

export function getShortNumber(input: any, decimals?: any) {
	if (!input) return input;
	input = Number(input);

	let exp;
	let number: string;
	const suffixes = ['K', 'M', 'B', 'T', 'P', 'E'];
	const isNegativeValues = input < 0;

	if (Number.isNaN(input) || (input < 1000 && input >= 0) || !isNumeric(input) || (input < 0 && input > -1000)) {
		if (!!decimals && isNumeric(input) && !(input < 0) && input != 0) {
			return trimTrailingDecimalZeros(input.toFixed(decimals));
		} else {
			return trimTrailingDecimalZeros(input);
		}
	}

	if (!isNegativeValues) {
		exp = Math.floor(Math.log(input) / Math.log(1000));
		number = (input / Math.pow(1000, exp)).toFixed(decimals);
	} else {
		input = input * -1;

		exp = Math.floor(Math.log(input) / Math.log(1000));
		number = ((input * -1) / Math.pow(1000, exp)).toFixed(decimals);
	}

	return trimTrailingDecimalZeros(number) + suffixes[exp - 1];
}

export function trimTrailingDecimalZeros(number: string) {
	// Start with the right side of the number string, and remove any trailing zeros
	if (number?.toString().indexOf('.') > -1) {
		let i = number.length - 1;
		while (number[i] === '0') {
			number = number.slice(0, i);
			i--;
		}

		// Then strip out the decimal point if there are no more digits after it.
		if (number[i] === '.') {
			number = number.slice(0, i);
		}
	}

	return number;
}

export function isNumeric(value): boolean {
	if (value < 0) value = value * -1;
	if (/^-{0,1}\d+$/.test(value)) {
		return true;
	} else if (/^\d+\.\d+$/.test(value)) {
		return true;
	} else {
		return false;
	}
}

/**
 * Get the letter equivalent of a number
 * Uses excel style A-Z then AA-ZZ
 * @param num
 */
export function numberToLetters(num) {
	let letters = '';
	while (num >= 0) {
		letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[num % 26] + letters;
		num = Math.floor(num / 26) - 1;
	}
	return letters;
}

/**
 * Get a number that is of a certain distance fro the original, based on a percentage of the original.
 * @param num
 * @param percentVariance Must be a float between 0 and 1
 */
export function getVariantNumber(num: number, percentVariance: number) {
	return num + Math.random() * (num * percentVariance) * (Math.round(Math.random()) * 2 - 1);
}

/**
 * Get a random number between a min and max.
 * @param min
 * @param max
 */
export function randomIntFromInterval(min: number, max: number) {
	// min and max included
	return Math.floor(Math.random() * (max - min + 1) + min);
}
