import { DocumentationFlow } from '../../models/dev/doc-overview.model';
import { DocumentationFlowTypeEnum } from '../../models/dev/doc-types.enum';
import { documentationAddNewGraphFlow } from './add-graph.flow';
import { documentationColumnOverview } from './columns.flow';
import { documentationFiltersOverview } from './filters.flow';
import { documentationReportingDashboardFlow } from './reporting-dashboard.flow';

export const documentationFlowsData: { [type in DocumentationFlowTypeEnum]: DocumentationFlow } = {
	[DocumentationFlowTypeEnum.FILTERS]: documentationFiltersOverview,
	[DocumentationFlowTypeEnum.REPORTING_DASHBOARD]: documentationReportingDashboardFlow,
	[DocumentationFlowTypeEnum.COLUMNS]: documentationColumnOverview,
	[DocumentationFlowTypeEnum.NEW_GRAPH]: documentationAddNewGraphFlow,
};
