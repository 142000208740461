import { Component, Input, OnInit } from '@angular/core';
import { documentationQAData } from '../../../data/qa/index';
import { DocumentationService } from '../../../services/documentation.service';
import { DocumentationQATypeEnum } from '../../../models/qa/doc-qa-types.model';

@Component({
	selector: 'app-documentation-qa-list',
	templateUrl: './qa-list.component.html',
	styleUrls: ['./qa-list.component.scss'],
})
export class DocumentationQaListComponent implements OnInit {
	@Input() qaKeys?: DocumentationQATypeEnum[];

	searchTerm?: string;

	qas: {
		title: string;
		description: string;
		key: DocumentationQATypeEnum;
	}[] = [];

	constructor(private readonly documentationService: DocumentationService) {}

	ngOnInit(): void {
		const keys = this.qaKeys || (Object.keys(documentationQAData) as DocumentationQATypeEnum[]);
		this.qas = keys.map((key: DocumentationQATypeEnum) => {
			const flow = documentationQAData[key];
			return {
				title: flow.title,
				description: flow.description,
				key,
			};
		});
	}

	onQAPressed(key: DocumentationQATypeEnum): void {
		this.documentationService.openQADataDialog(key);
	}
}
