import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentationQABasicDirection } from '../../../models/qa/doc-qa-basic.model';

@Component({
	selector: 'app-documentation-qa-directions-overview',
	templateUrl: 'qa-directions-overview.component.html',
	styleUrls: ['qa-directions-overview.component.scss'],
})
export class DocumentationQADirectionOverviewComponent implements OnInit {
	directions: DocumentationQABasicDirection[];

	constructor(@Inject(MAT_DIALOG_DATA) private readonly dialogData: DocumentationQABasicDirection[]) {}

	ngOnInit(): void {
		this.directions = this.dialogData;
	}

	get totalItems(): number {
		return this.countItems(this.directions);
	}

	get completedItems(): number {
		return this.countCompletedItems(this.directions);
	}

	get progressValue(): number {
		return (this.completedItems / this.totalItems) * 100;
	}

	countItems(items: DocumentationQABasicDirection[]): number {
		let count = items.length;
		for (const item of items) {
			if (item.items) {
				count += this.countItems(item.items);
			}
		}
		return count;
	}

	countCompletedItems(items: DocumentationQABasicDirection[]): number {
		let count = items.filter((item) => item.completed).length;
		for (const item of items) {
			if (item.items) {
				count += this.countCompletedItems(item.items);
			}
		}
		return count;
	}

	toggleChildren(item: DocumentationQABasicDirection): void {
		if (item.items) {
			item.items.forEach((child) => {
				child.completed = item.completed;
				this.toggleChildren(child);
			});
		}
	}

	updateParent(item: DocumentationQABasicDirection, parentList: DocumentationQABasicDirection[]): void {
		const allCompleted = parentList.every((child) => child.completed);
		const parentItem = this.findParentItem(this.directions, item);
		if (parentItem) {
			parentItem.completed = allCompleted;
			this.updateParent(parentItem, this.getSiblingItems(parentItem));
		}
	}

	findParentItem(
		items: DocumentationQABasicDirection[],
		targetItem: DocumentationQABasicDirection
	): DocumentationQABasicDirection | null {
		for (const item of items) {
			if (item.items && item.items.includes(targetItem)) {
				return item;
			} else if (item.items) {
				const parent = this.findParentItem(item.items, targetItem);
				if (parent) {
					return parent;
				}
			}
		}
		return null;
	}

	getSiblingItems(item: DocumentationQABasicDirection): DocumentationQABasicDirection[] {
		const parentItem = this.findParentItem(this.directions, item);
		return parentItem ? parentItem.items || [] : this.directions;
	}

	onCheckboxChange(item: DocumentationQABasicDirection): void {
		this.toggleChildren(item);

		const parentItem = this.findParentItem(this.directions, item);
		if (parentItem) {
			this.updateParent(item, parentItem.items || []);
		}
	}

	onReset(): void {
		this.directions.forEach((item) => {
			item.completed = false;
			this.toggleChildren(item);
		});
	}
}
