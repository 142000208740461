import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapByCustom',
})
export class MapByCustomPipe implements PipeTransform {
  transform<T>(value: T, callbackFn: (value, ...args) => any, ...args): any {
    try {
      value = callbackFn(value, ...args);
      return value;
    } catch (e) {
      return undefined;
    }
  }
}
