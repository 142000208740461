import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { documentationInterfacesData } from '../data/interfaces';
import { MatDialog } from '@angular/material/dialog';
import { DocumentationModelComponent } from '../components/dev/documentation-model/documentation-model.component';
import { DocumentationFlowSectionFormatTypeEnum } from '../models/dev/doc-overview.model';
import { documentationFlowsData } from '../data/flows';
import { DocumentationFlowOverviewComponent } from '../components/dev/flow-overview/flow-overview.component';
import { documentationComponentsData } from '../data/components';
import { DocumentationComponentOverview } from '../components/dev/component-overview/component-overview.component';
import { DocumentationTypeOption } from '../models/dev/doc-types.enum';
import { DocumentationQATypeEnum } from '../models/qa/doc-qa-types.model';
import { documentationQAData } from '../data/qa';
import { DocumentationQAOverviewComponent } from '../components/qa/qa-overview/qa-overview.component';
import { DocumentationQADirectionOverviewComponent } from '../components/qa/qa-directions-overview/qa-directions-overview.component';
import { DocumentationQABasicDirection } from '../models/qa/doc-qa-basic.model';

@Injectable({
	providedIn: 'root',
})
export class DocumentationService {
	private _isActive = new BehaviorSubject<boolean>(false);
	isActive$ = this._isActive.asObservable();

	private _menuExpanded = new BehaviorSubject<boolean>(false);
	menuExpanded$ = this._menuExpanded.asObservable();

	constructor(private readonly snackBar: MatSnackBar, private readonly dialog: MatDialog) {}

	toggleInteractiveMode(): void {
		this._isActive.next(!this._isActive.value);
	}

	toggleMenu(): void {
		this._menuExpanded.next(!this._menuExpanded.value);
	}

	copyTextToClipboard(relativePath: string): void {
		navigator.clipboard.writeText(relativePath).then(() => {
			this.snackBar.open('Copied to clipboard', undefined, {
				verticalPosition: 'bottom',
				horizontalPosition: 'center',
				duration: 1000,
				panelClass: 'success',
			});
		});
	}

	openDataDialog(name: DocumentationTypeOption, type: DocumentationFlowSectionFormatTypeEnum): void {
		switch (type) {
			case DocumentationFlowSectionFormatTypeEnum.INTERFACE: {
				const interfaceData = documentationInterfacesData[name];

				if (!interfaceData) {
					console.error('No data found for this interface');
					return;
				}

				this.dialog.open(DocumentationModelComponent, {
					data: interfaceData,
					disableClose: false,
					panelClass: ['fullscreen', 'background-color', 'max-width-md'],
				});
				return;
			}

			case DocumentationFlowSectionFormatTypeEnum.FLOW: {
				const flowData = documentationFlowsData[name];

				if (!flowData) {
					console.error('No data found for this flow');
					return;
				}

				this.dialog.open(DocumentationFlowOverviewComponent, {
					data: flowData,
					disableClose: false,
					panelClass: ['fullscreen', 'background-color'],
				});
				return;
			}

			case DocumentationFlowSectionFormatTypeEnum.COMPONENT: {
				const componentData = documentationComponentsData[name];

				if (!componentData) {
					console.error('No data found for this component');
					return;
				}

				this.dialog.open(DocumentationComponentOverview, {
					data: componentData,
					disableClose: false,
					panelClass: ['fullscreen', 'background-color'],
				});
				return;
			}

			default:
				console.error('No data found for this type');
				return null;
		}
	}

	openQADataDialog(key: DocumentationQATypeEnum): void {
		this.dialog.open(DocumentationQAOverviewComponent, {
			data: documentationQAData[key],
			disableClose: false,
			panelClass: ['fullscreen', 'background-color'],
		});
	}

	openQADirectionsDialog(directions: DocumentationQABasicDirection[]): void {
		this.dialog.open(DocumentationQADirectionOverviewComponent, {
			data: directions,
			disableClose: false,
			panelClass: ['fullscreen', 'background-color'],
		});
	}
}
