import { CommonModule } from '@angular/common';
import { materialModules } from '../angular-material.module';
import { NgModule } from '@angular/core';
import { DocumentationInteractiveDirective } from './directives/documentation-interactive.directive';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedModule } from '../shared/shared.module';
import { DocumentationDialogComponent } from './components/documentation-dialog/documentation-dialog.component';
import { CodeDisplayComponent } from './components/dev/code-display/code-display.component';
import { DocumentationComponentOverview } from './components/dev/component-overview/component-overview.component';
import { DocumentationDynamicTypeComponent } from './components/dev/dynamic-type/dynamic-type.component';
import { DocumentationModelComponent } from './components/dev/documentation-model/documentation-model.component';
import { DocumentationFlowOverviewComponent } from './components/dev/flow-overview/flow-overview.component';
import { DocumentationFlowsListComponent } from './components/dev/flows-list/flows-list.component';
import { DocumentationComponentsListComponent } from './components/dev/components-list/components-list.component';
import { DocumentationQaListComponent } from './components/qa/qa-list/qa-list.component';
import { DocumentationSearchComponent } from './components/doc-search/doc-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DocumentationSearchFilterPipe } from './pipes/doc-search-filter.pipe';
import { DocumentationQAOverviewComponent } from './components/qa/qa-overview/qa-overview.component';
import { DocumentationQADirectionOverviewComponent } from './components/qa/qa-directions-overview/qa-directions-overview.component';

const COMPONENTS = [
	DocumentationDialogComponent,
	DocumentationQaListComponent,
	DocumentationModelComponent,
	CodeDisplayComponent,
	DocumentationComponentOverview,
	DocumentationFlowOverviewComponent,
	DocumentationDynamicTypeComponent,
	DocumentationFlowsListComponent,
	DocumentationComponentsListComponent,
	DocumentationSearchComponent,
	DocumentationQAOverviewComponent,
	DocumentationQADirectionOverviewComponent,
];

const DIRECTIVES = [DocumentationInteractiveDirective];

const PIPES = [DocumentationSearchFilterPipe];

@NgModule({
	imports: [CommonModule, ...materialModules, FlexLayoutModule, SharedModule, FormsModule, ReactiveFormsModule],
	providers: [],
	declarations: [...COMPONENTS, ...DIRECTIVES, ...PIPES],
	exports: [DocumentationInteractiveDirective],
})
export class DocumentationModule {}
