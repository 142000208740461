import { DocumentationQABasic } from '../../../models/qa/doc-qa-basic.model';
import { DocumentationQASectionTypeEnum } from '../../../models/qa/doc-qa-types.model';

export const documentationComplianceTrackerDashboardQA: DocumentationQABasic = {
	title: 'Compliance Tracker Dashboard',
	description:
		'The Compliance Tracker Dashboard identifies which programs are missing required and important data in SIMPL. Only Agency Users can see this dashboard.',
	sections: [
		{
			type: DocumentationQASectionTypeEnum.MAIN_TITLE,
			text: 'Dashboard Overview',
		},
		{
			type: DocumentationQASectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'The Compliance Tracker Dashboard checks if fields are blank to help clients complete data. Compliance is divided into two phases: Phase 1 covers fields completed during planning and execution, while Phase 2 covers fields completed after program execution when results are received.',
			formats: [
				{
					textIdentifier: 'Phase 1',
					styling: ['bold'],
				},
				{
					textIdentifier: 'fields completed during planning and execution',
					styling: ['italic'],
				},
				{
					textIdentifier: 'Phase 2',
					styling: ['bold'],
				},
				{
					textIdentifier: 'fields completed after program execution when results are received',
					styling: ['italic'],
				},
			],
		},
		{
			type: DocumentationQASectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'The dashboard begins with broad, high-level widgets, which become more detailed as you navigate down.',
		},
		{
			type: DocumentationQASectionTypeEnum.MAIN_TITLE,
			text: 'Dashboard Widgets',
		},
		{
			type: DocumentationQASectionTypeEnum.SUBTITLE,
			text: 'Total Compliance',
		},
		{
			type: DocumentationQASectionTypeEnum.BULLETED_LIST,
			text: '',
			bulletedList: [
				'Displays the total compliance rate or total amount of required fields completed for programs based on your filters.',
			],
			formats: [
				{
					textIdentifier: 'Total Compliance',
					styling: ['bold'],
				},
			],
		},
		{
			type: DocumentationQASectionTypeEnum.SUBTITLE,
			text: 'Total Compliance by Phase',
		},
		{
			type: DocumentationQASectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'This section breaks compliance out further to provide compliance rates based on Phase. It’s important to consider the time of year when viewing compliance rates, as Phase 1 compliance is expected to be higher at the start of the year, while Phase 2 compliance remains low until programs are completed and results are available.',
		},
		{
			type: DocumentationQASectionTypeEnum.SUBTITLE,
			text: 'Compliance by Retailer',
		},
		{
			type: DocumentationQASectionTypeEnum.BULLETED_LIST,
			text: 'Provides compliance by phase and retailer. Each phase has its own widget that shows:',
			bulletedList: [
				'Total number of programs per retailer.',
				'Number of programs missing key fields.',
				'Phase compliance rate for each retailer.',
			],
		},
		{
			type: DocumentationQASectionTypeEnum.SUBTITLE,
			text: 'Compliance by Program',
		},
		{
			type: DocumentationQASectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'These widgets provide compliance information by program, allowing CE leads to quickly identify programs with incomplete data. The table shows the last modified date, complete (Yes) and incomplete (No) fields, and a Phase compliance rate for each program.',
			formats: [
				{
					textIdentifier: 'complete (Yes)',
					styling: ['bold'],
				},
				{
					textIdentifier: 'incomplete (No)',
					styling: ['bold'],
				},
			],
		},
	],
	directions: [
		{
			title: 'Create a New Budget Period',
			completed: false,
		},
		{
			title: 'Create a Program',
			completed: false,
			items: [
				{
					title: 'Complete several fields on the details tab without adding any tactics.',
					completed: false,
				},
			],
		},
		{
			title: 'Refresh and Check the Dashboard',
			completed: false,
			items: [
				{
					title: 'Refresh the page, then navigate to the Compliance Tracker Dashboard and filter for the newly created budget period.',
					completed: false,
				},
			],
		},
		{
			title: 'QA Checklist',
			completed: false,
			items: [
				{
					title: 'Check the Compliance by Program widgets. Ensure that “Yes” indicates all fields are complete, and “No” indicates incomplete fields.',
					completed: false,
				},
				{
					title: 'Verify that tactic fields are marked compliant if there are no tactics (i.e., Tactic Vendor, Tactic Cost, Metrics, and Metric Results should be marked “Yes”).',
					completed: false,
				},
				{
					title: 'Manually calculate the compliance rate for each phase. Count the number of “Yes” fields divided by the total number of fields being counted to ensure accuracy.',
					completed: false,
				},
				{
					title: 'Check the Compliance by Retailer widget to ensure it matches exactly, given only one program is in the budget period.',
					completed: false,
				},
				{
					title: 'Ensure the Compliance by Phase matches exactly.',
					completed: false,
				},
				{
					title: 'Calculate the total compliance: Count the number of “Yes” fields across both phases and divide by the total number of fields across both phases.',
					completed: false,
				},
			],
		},
		{
			title: 'Create Additional Programs',
			completed: false,
			items: [
				{
					title: 'Return to the activate tab and create an additional program with a different retailer and one tactic.',
					completed: false,
				},
			],
		},
		{
			title: 'Recheck the Dashboard',
			completed: false,
			items: [
				{
					title: 'Verify that the dashboard updates accurately by repeating the steps in the QA checklist.',
					completed: false,
				},
			],
		},
		{
			title: 'Create an Additional Program with Two Tactics',
			completed: false,
			items: [
				{
					title: 'Create a program with a different retailer and two tactics: one with all fields complete and another with multiple blank fields.',
					completed: false,
				},
			],
		},
		{
			title: 'Recheck the Dashboard',
			completed: false,
			items: [
				{
					title: 'Verify that the dashboard updates accurately by repeating the QA checklist.',
					completed: false,
				},
				{
					title: 'Ensure that if any tactic in a program is missing data, the program is marked non-compliant for that field.',
					completed: false,
				},
			],
		},
		{
			title: 'Check the Dashboard for Multiple Budget Years',
			completed: false,
			items: [
				{
					title: 'Create a new budget year.',
					completed: false,
				},
				{
					title: 'Add a program with multiple tactics.',
					completed: false,
				},
			],
		},
		{
			title: 'Recheck the Dashboard',
			completed: false,
			items: [
				{
					title: 'Verify that the dashboard updates accurately by filtering to two budget periods and checking each widget.',
					completed: false,
				},
				{
					title: 'Check the remaining filters.',
					completed: false,
				},
			],
		},
	],
};
