import { DocumentationQABasic } from '../../../models/qa/doc-qa-basic.model';
import { DocumentationQASectionTypeEnum } from '../../../models/qa/doc-qa-types.model';

export const documentationClientDropDownDashboardQA: DocumentationQABasic = {
	title: 'Client Drop-Down Dashboard',
	description:
		'The Client Drop-Down Dashboard summarizes each drop-down field to help identify available options and determine if additional items are needed. Only Agency Users can see this dashboard.',
	sections: [
		{
			type: DocumentationQASectionTypeEnum.MAIN_TITLE,
			text: 'Dashboard Overview',
		},
		{
			type: DocumentationQASectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'The dashboard begins with broad, high-level widgets, which become more detailed as you navigate down.',
		},
		{
			type: DocumentationQASectionTypeEnum.MAIN_TITLE,
			text: 'Dashboard Widgets',
		},
		{
			type: DocumentationQASectionTypeEnum.SUBTITLE,
			text: 'Program Level Drop-Downs',
		},
		{
			type: DocumentationQASectionTypeEnum.BULLETED_LIST,
			text: 'Summarizes the following drop-down fields with counts:',
			bulletedList: [
				'Brands – includes counts',
				'Retailers – includes counts',
				'Program Types – includes counts',
				'Brand Initiatives – includes counts',
				'Program Utilization – includes counts',
				'Location – includes counts',
				'Agency – includes counts',
				'Program Phases – includes counts',
				'Program Sectors – includes counts',
				'Funding Sources',
				'Funding Types',
				'External Identity Types',
			],
			formats: [
				{
					textIdentifier: 'Brands',
					styling: ['bold'],
				},
				{
					textIdentifier: 'Retailers',
					styling: ['bold'],
				},
				{
					textIdentifier: 'Program Types',
					styling: ['bold'],
				},
				{
					textIdentifier: 'Brand Initiatives',
					styling: ['bold'],
				},
				{
					textIdentifier: 'Program Utilization',
					styling: ['bold'],
				},
				{
					textIdentifier: 'Location',
					styling: ['bold'],
				},
				{
					textIdentifier: 'Agency',
					styling: ['bold'],
				},
				{
					textIdentifier: 'Program Phases',
					styling: ['bold'],
				},
				{
					textIdentifier: 'Program Sectors',
					styling: ['bold'],
				},
				{
					textIdentifier: 'Funding Sources',
					styling: ['bold'],
				},
				{
					textIdentifier: 'Funding Types',
					styling: ['bold'],
				},
				{
					textIdentifier: 'External Identity Types',
					styling: ['bold'],
				},
			],
		},
		{
			type: DocumentationQASectionTypeEnum.SUBTITLE,
			text: 'Tactic Level Drop-Downs',
		},
		{
			type: DocumentationQASectionTypeEnum.BULLETED_LIST,
			text: 'Summarizes the following drop-down fields with counts:',
			bulletedList: [
				'Tactic Types – includes counts',
				'Tactic Phases – includes counts',
				'Tactic Agency – includes counts',
				'Tactic Location – includes counts',
				'Cost Types – includes counts',
				'Vendors – includes counts',
				'Measurement Types',
				'Products',
			],
			formats: [
				{
					textIdentifier: 'Tactic Types',
					styling: ['bold'],
				},
				{
					textIdentifier: 'Tactic Phases',
					styling: ['bold'],
				},
				{
					textIdentifier: 'Tactic Agency',
					styling: ['bold'],
				},
				{
					textIdentifier: 'Tactic Location',
					styling: ['bold'],
				},
				{
					textIdentifier: 'Cost Types',
					styling: ['bold'],
				},
				{
					textIdentifier: 'Vendors',
					styling: ['bold'],
				},
				{
					textIdentifier: 'Measurement Types',
					styling: ['bold'],
				},
				{
					textIdentifier: 'Products',
					styling: ['bold'],
				},
			],
		},
	],
	directions: [
		{
			title: 'Create a New Program and Check Drop-Downs',
			completed: false,
			items: [
				{
					title: 'Click each drop-down and check it against the dashboard.',
					completed: false,
				},
				{
					title: 'Log into the CMS and check it against the dashboard to ensure everything from the CMS flows into the instance and the dashboard.',
					completed: false,
				},
				{
					title: 'Ensure that the counts are accurate and that the drop-down fields are completed.',
					completed: false,
				},
			],
		},
		{
			title: 'Refresh and Check the Dashboard',
			completed: false,
			items: [
				{
					title: 'Refresh the page, then navigate to the Client Drop-Down Dashboard and filter for the newly created budget period.',
					completed: false,
				},
				{
					title: 'Ensure all counts are 1 for the options in the program, and the rest are zero.',
					completed: false,
				},
			],
		},
		{
			title: 'Check an Existing Budget Period',
			completed: false,
			items: [
				{
					title: 'Navigate to the Activate tab. In table view, turn on the columns you want to check against the dashboard.',
					completed: false,
				},
				{
					title: 'Add the count column and use the group-by feature to check the total number of programs by brand, retailer, etc. This should match the dashboard.',
					completed: false,
				},
				{
					title: 'For remaining fields, export all programs and tactics to Excel.',
					completed: false,
				},
				{
					title: 'In Excel, spot-check by counting the total of an option for each field and ensure it matches the dashboard.',
					completed: false,
				},
				{
					title: 'For a program with multiple brands, ensure the program counts for each brand match the dashboard.',
					completed: false,
				},
			],
		},
		{
			title: 'Check the Dashboard for Multiple Budget Years',
			completed: false,
			items: [
				{
					title: 'Create a new budget year.',
					completed: false,
				},
				{
					title: 'Add a program with multiple tactics.',
					completed: false,
				},
			],
		},
		{
			title: 'Recheck the Dashboard',
			completed: false,
			items: [
				{
					title: 'Verify that the dashboard updates accurately by filtering for two budget periods. Check each widget to ensure they work properly.',
					completed: false,
				},
				{
					title: 'Check the remaining filters.',
					completed: false,
				},
			],
		},
	],
};
