import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { filter, map } from 'rxjs/operators';
import { Brand } from '../../../../../api/src/brand/brand.entity';
import { getDateAsPostgresStyle } from '../../_core/utils/date.utils';
import { AppSection, GlobalState } from './global.model';
import { GlobalStore } from './global.store';
import { PublicBudgetPeriod } from '../../../../../api/src/budget-period/budget-period.entity';
import { ProgramMetadataSettings } from '../entities/program/program.data';

@Injectable({ providedIn: 'root' })
export class GlobalQuery extends Query<GlobalState> {
	public organizationLogo$ = this.select().pipe(map((state) => state.settings?.logo));

	// Get Settings only if the user is authenticated
	public authenticatedSettings$ = this.select('settings').pipe(filter((settings) => (settings?.budgetPeriods ? true : false)));

	constructor(protected store: GlobalStore) {
		super(store);
	}

	getAppSection(): AppSection {
		return this.getValue().appSection;
	}

	getCurrentBudgetPeriod(section: AppSection) {
		const postgresTodayDate = getDateAsPostgresStyle(new Date(Date.now() + 1000 * 60 * -new Date().getTimezoneOffset()));

		// Get the budget with the correct app section
		const budgetPeriods = [...this.getValue().settings.budgetPeriods];

		let activeBudgetPeriods = [];
		let selectedBudgetPeriod;

		if (section === 'activation') {
			// Get budgets that haven't ended
			activeBudgetPeriods = budgetPeriods.filter((period) => period.end > postgresTodayDate && period?.state === 'activation');

			// Get the budget with the earliest start date
			selectedBudgetPeriod = activeBudgetPeriods
				.slice()
				.sort((a, b) => (a['start'] > b['start'] ? 1 : b['start'] > a['start'] ? -1 : 0));
		} else {
			// Get the budget with the latest start date
			selectedBudgetPeriod = budgetPeriods.slice().sort((a, b) => (a['start'] < b['start'] ? 1 : b['start'] < a['start'] ? -1 : 0));
		}

		// console.log('Budget Period', selectedBudgetPeriod[0] || budgetPeriods[0], budgetPeriods, postgresTodayDate, selectedBudgetPeriod);

		// Return the first budget that hasn't ended, or just the first budget with the right section.
		return selectedBudgetPeriod[0] || activeBudgetPeriods[0] || budgetPeriods[0];
	}

	/**
	 * Get the budget period that started before the one provided.
	 * @param id
	 */
	getPreviousBudgetPeriod(budgetPeriod: PublicBudgetPeriod) {
		// Find the budget period with the closests start date to the one provided
		const budgetPeriods = this.getValue()
			.settings.budgetPeriods.slice()
			.sort((a, b) => (a['start'] < b['start'] ? 1 : b['start'] < a['start'] ? -1 : 0));
		const budgetPeriodIndex = budgetPeriods.findIndex((bP) => bP.id === budgetPeriod.id);
		const previousBudgetPeriod = budgetPeriods[budgetPeriodIndex - 1];
		return previousBudgetPeriod;
	}

	getBudgetPeriod(id: PublicBudgetPeriod['id']) {
		return this.getValue().settings.budgetPeriods.find((bP) => bP.id === id);
	}

	getSetting(name: string) {
		return this.getValue().settings[name];
	}

	getOrganizationSetting(name: string) {
		return this.getValue().settings.settings[name];
	}

	getEntitySettings() {
		return this.getValue().settings?.settings?.entities;
	}

	getDefaultMetadataSettings(entity: string) {
		switch (entity) {
			case 'program':
				return ProgramMetadataSettings;
			default:
				return null;
		}
	}

	getIntegration(name: string) {
		return this.getOrganizationSetting('integrations')?.[name];
	}

	getBrandColor(brandId: Brand['id'], brandName?: Brand['name']) {
		return this.getValue().settings.brands.find((b) => b.id == brandId || b.name == brandName)?.color;
	}

	/**
	 * Returns hydrated Funding Sources that match the specified planning sources from a budget period.
	 * @param budgetPeriod
	 */
	getBudgetPeriodPlanningFundingSources(budgetPeriodId: PublicBudgetPeriod['id']) {
		const budgetPeriod = this.getBudgetPeriod(budgetPeriodId);
		const fundingSources = this.getValue().settings.fundingSources;
		const planningFundingSources = budgetPeriod.planningFundingSources;
		const hydratedPlanningFundingSources = [];

		planningFundingSources?.forEach((planningFundingSource) => {
			const fundingSource = fundingSources.find((fs) => fs.id === planningFundingSource.id);
			hydratedPlanningFundingSources.push(fundingSource);
		});

		return hydratedPlanningFundingSources.length > 0 ? hydratedPlanningFundingSources : fundingSources;
	}

	/**
	 * Returns hydrated Funding Types that match the specified planning sources from a budget period.
	 * @param budgetPeriod
	 */
	getBudgetPeriodPlanningFundingTypes(budgetPeriodId: PublicBudgetPeriod['id']) {
		const budgetPeriod = this.getBudgetPeriod(budgetPeriodId);
		const fundingTypes = this.getValue().settings.fundingTypes;
		const planningFundingTypes = budgetPeriod.planningFundingTypes;
		const hydratedPlanningFundingTypes = [];

		planningFundingTypes?.forEach((planningFundingType) => {
			const fundingType = fundingTypes.find((fs) => fs.id === planningFundingType.id);
			hydratedPlanningFundingTypes.push(fundingType);
		});

		return hydratedPlanningFundingTypes.length > 0 ? hydratedPlanningFundingTypes : fundingTypes;
	}

	getDefaultSettingsOrgsBudgetPeriod(section: AppSection) {
		const defaultPlanBP = this.getOrganizationSetting('defaultPlanBP');
		const defaultActivateBP = this.getOrganizationSetting('defaultActivateBP');

		if (section === 'planning') {
			return this.getBudgetPeriod(defaultPlanBP?.id) || this.getCurrentBudgetPeriod(section);
		} else {
			return this.getBudgetPeriod(defaultActivateBP?.id) || this.getCurrentBudgetPeriod(section);
		}
	}
}
