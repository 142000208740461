import { Component, Input, OnInit } from '@angular/core';
import { documentationFlowsData } from '../../../data/flows/index';
import { DocumentationService } from '../../../services/documentation.service';
import { DocumentationFlowSectionFormatTypeEnum } from '../../../models/dev/doc-overview.model';
import { DocumentationFlowTypeEnum } from '../../../models/dev/doc-types.enum';

@Component({
	selector: 'app-documentation-flows-list',
	templateUrl: 'flows-list.component.html',
	styleUrls: ['flows-list.component.scss'],
})
export class DocumentationFlowsListComponent implements OnInit {
	@Input() flowKeys?: DocumentationFlowTypeEnum[];
	@Input() embedded: boolean = false;

	flows: {
		title: string;
		description: string;
		key: DocumentationFlowTypeEnum;
	}[] = [];

	searchTerm?: string;

	constructor(private readonly documentationService: DocumentationService) {}

	ngOnInit(): void {
		const keys = this.flowKeys || (Object.keys(documentationFlowsData) as DocumentationFlowTypeEnum[]);
		this.flows = keys.map((key: DocumentationFlowTypeEnum) => {
			const flow = documentationFlowsData[key];
			return {
				title: flow.title,
				description: flow.description,
				key,
			};
		});
	}

	onFlowPressed(key: DocumentationFlowTypeEnum): void {
		this.documentationService.openDataDialog(key, DocumentationFlowSectionFormatTypeEnum.FLOW);
	}
}
