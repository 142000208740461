import {
	DocumentationFlow,
	DocumentationFlowSectionFormatTypeEnum,
	DocumentationFlowSectionTypeEnum,
} from '../../models/dev/doc-overview.model';

export const documentationAddNewGraphFlow: DocumentationFlow = {
	title: 'Add New Graph (Chart)',
	description: 'To add a new graph to the application, follow the steps below. This guide walks you through the entire process.',
	sections: [
		// 1. Add New Graph Enum
		{
			type: DocumentationFlowSectionTypeEnum.SUBTITLE,
			text: '1. Add New Graph Enum',
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'All graph types are defined in the api/src/graph/models/index.ts file. Begin by adding a new graph type to the Graph enum in this file.',
			formats: [
				{
					textIdentifier: 'api/src/graph/models/index.ts file',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
			],
		},

		// 2. Add New Graph Record Grouping
		{
			type: DocumentationFlowSectionTypeEnum.SUBTITLE,
			text: '2. Add New Graph Record Grouping',
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'At the bottom, you will see the GraphRecordGrouping interface, which is used to group records for a graph. Add a new grouping type to this interface if needed.',
			formats: [
				{
					textIdentifier: 'GraphRecordGrouping',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
			],
		},

		// 3. Add New GraphResponse
		{
			type: DocumentationFlowSectionTypeEnum.SUBTITLE,
			text: '3. Add New GraphResponse',
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'In the same file, locate the GraphResponse interface and add a new type of graph response to this interface.',
			formats: [
				{
					textIdentifier: 'api/src/graph/models/index.ts',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
				{
					textIdentifier: 'GraphResponse',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
			],
		},

		// 4. Modify GraphHashMap
		{
			type: DocumentationFlowSectionTypeEnum.SUBTITLE,
			text: '4. Modify GraphHashMap',
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'In the graph.service.ts file, locate the GraphHashMap type. Update this type to include the new graph. For example, if adding a graph called BudgetPeriodSummary, you would add:',
			formats: [
				{
					textIdentifier: 'graph.service.ts',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
			],
		},
		{
			type: DocumentationFlowSectionTypeEnum.CODE_SNIPPET,
			text: `[Graph.BudgetPeriodSummary]?: { [key: string]: { [key: string]: GraphResponse[Graph.BudgetPeriodSummary] } };`,
		},

		// 5. Add Graph Triggers
		{
			type: DocumentationFlowSectionTypeEnum.SUBTITLE,
			text: '5. Add Graph Triggers',
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'Graph triggers retrieve and format data for a graph. Add a new trigger to the graph-2.service.ts file for the graph you are adding. Below is an example of a graph trigger.',
			formats: [
				{
					textIdentifier: 'graph-2.service.ts',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
			],
		},
		{
			type: DocumentationFlowSectionTypeEnum.CODE_SNIPPET,
			text: `
  {
	  triggers: [Graph.InvestmentDisbursementTacticCategoryImpression],
	  graph: {
		  name: Graph.InvestmentDisbursementTacticCategoryImpression,
		  group: GraphRecordGrouping.TacticCategoryImpression
	  }
  }
  `,
		},

		// 6. Modify getSelects Method
		{
			type: DocumentationFlowSectionTypeEnum.SUBTITLE,
			text: '6. Modify getSelects Method',
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'In the graph-2.service.ts file, find the getSelects method. Modify this method to include the data needed for the new graph. For example, if you need Brand Initiative data for each program, add it (if not already included):',
			formats: [
				{
					textIdentifier: 'graph-2.service.ts',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
			],
		},
		{
			type: DocumentationFlowSectionTypeEnum.CODE_SNIPPET,
			text: `programSelects.add(ProgramSelect.BrandInitiative);`,
		},

		// 7. Define Grouping
		{
			type: DocumentationFlowSectionTypeEnum.SUBTITLE,
			text: '7. Define Grouping',
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'In the program-graph.utils.ts file, find the programsToGraphRecord method. This method formats data according to your needs. Define any new grouping here if you have added one.',
			formats: [
				{
					textIdentifier: 'program-graph.utils.ts',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
				{
					textIdentifier: 'programsToGraphRecord',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
			],
		},

		// 8. Define Graph Options in investment-disbursement.data.ts
		{
			type: DocumentationFlowSectionTypeEnum.SUBTITLE,
			text: '8. Define Graph Options',
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'In the investment-disbursement.data.ts file, locate the chartTypes object. Add a new graph option to this object.',
			formats: [
				{
					textIdentifier: 'investment-disbursement.data.ts',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
				{
					textIdentifier: 'chartTypes',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
			],
		},

		// 9. Enable Data Merging
		{
			type: DocumentationFlowSectionTypeEnum.SUBTITLE,
			text: '9. Enable Data Merging',
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'In the web/src/app/state/entities/graph/graph.utils.ts file, locate the mergeGraphResults method. This method merges data for multiple budget periods.',
			formats: [
				{
					textIdentifier: 'web/src/app/state/entities/graph/graph.utils.ts',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
				{
					textIdentifier: 'mergeGraphResults',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
			],
		},
	],
};
