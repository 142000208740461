import { Component, Input, OnInit } from '@angular/core';
import { DocumentationService } from '../../../services/documentation.service';
import { DocumentationFlowSectionFormatTypeEnum } from '../../../models/dev/doc-overview.model';
import { DocumentationComponentTypeEnum } from '../../../models/dev/doc-types.enum';
import { documentationComponentsData } from '../../../data/components/index';

@Component({
	selector: 'app-documentation-components-list',
	templateUrl: 'components-list.component.html',
	styleUrls: ['components-list.component.scss']
})
export class DocumentationComponentsListComponent implements OnInit {
	@Input() componentKeys: DocumentationComponentTypeEnum[];

	components: {
		title: string;
		description: string;
		key: DocumentationComponentTypeEnum;
	}[] = [];

	constructor(private readonly documentationService: DocumentationService) {}

	ngOnInit(): void {
		this.components = this.componentKeys.map((key: DocumentationComponentTypeEnum) => {
			const comp = documentationComponentsData[key];
			return {
				title: comp.title,
				description: comp.description,
				key
			};
		});
	}

	onFlowPressed(key: DocumentationComponentTypeEnum): void {
		this.documentationService.openDataDialog(key, DocumentationFlowSectionFormatTypeEnum.COMPONENT);
	}
}
