<div class="code-display-container">
	<mat-expansion-panel
		expanded
		class="panels-expansion-panel"
		[expanded]="false"
		[ngClass]="'language-' + language"
		*ngIf="useExpansionPanel; else justCode"
	>
		<mat-expansion-panel-header>
			<mat-panel-title> {{ methodName }} </mat-panel-title>
		</mat-expansion-panel-header>

		<ng-container [ngTemplateOutlet]="justCode"></ng-container>
	</mat-expansion-panel>
</div>

<ng-template #justCode>
	<pre style="padding: 0; margin: 0"><code [ngClass]="'language-' + language">{{ code | mapByCustom: trim }}</code></pre>
</ng-template>
