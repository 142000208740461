<main class="flex stretch same-padding-bottom">
	<app-sidebar-nav></app-sidebar-nav>

	<div class="center-container">
		<div class="content-container">
			<div class="left-content">
				<span>404 Error</span>
				<h1>We can't find that page</h1>
				<p>Sorry, the page you're looking for doesn't exist or has been moved.</p>

				<div class="button-container">
					<button class="back" (click)="location.back()">
						<i class="uil-arrow-left"></i>
						<span>Go Back</span>
					</button>

					<button class="home" (click)="router.navigate(['home'])">Take me home</button>
				</div>
			</div>

			<div class="right-content">
				<img src="../../../assets/404-image.webp" alt="404 Image" />
			</div>
		</div>
	</div>
</main>
