<div mat-dialog-title fxLayout="row">
	<div fxLayout="column">
		<div class="doc-dialog-title-row">
			<h3>{{ data.title }}</h3>
			<i class="uil-copy" *ngIf="data?.path" (click)="documentationService.copyTextToClipboard(data.path)"></i>
		</div>
		<p class="fw-300 mt-4">
			{{ data.description }}
		</p>
	</div>
	<div class="actions margin-left-auto">
		<button mat-flat-button color="secondary" class="button-extra-small" [mat-dialog-close]="false">Close</button>
	</div>
</div>

<div class="dev-container">
	<!-- Flows -->
	<ng-container *ngIf="data?.flows">
		<div class="doc-container-title">Important Flows</div>
		<app-documentation-flows-list [flowKeys]="data.flows" [embedded]="true"></app-documentation-flows-list>
	</ng-container>

	<!-- Important Components -->
	<ng-container *ngIf="data?.components">
		<div class="doc-container-title">Important Components</div>
		<app-documentation-components-list [componentKeys]="data.components"></app-documentation-components-list>
	</ng-container>

	<!-- Decorators -->
	<ng-container *ngFor="let decoratorObject of decorators">
		<div class="doc-container-title">{{ decoratorObject.title }}</div>
		<div class="doc-light-container">
			<div class="doc-property-list">
				<div class="doc-decorator" *ngFor="let decorator of decoratorObject.decorators.items">
					<span class="decorator-mark" *ngIf="decoratorObject.type !== 'properties'">
						{{ decoratorObject.type === "inputs" ? "@Input()" : "@Output()" }}
					</span>
					<span class="decorator-name">{{ decorator.name }}: </span>
					<div class="decorator-type">
						<app-documentation-dynamic-type
							[text]="decorator.type"
							[formats]="decoratorObject.decorators.formats"
						></app-documentation-dynamic-type>
						;
					</div>
					<span class="decorator-value" *ngIf="decorator?.value">= {{ decorator.value }};</span>
					<span class="doc-comment" *ngIf="decorator?.comment">{{ decorator.comment }}</span>
				</div>
			</div>
		</div>
	</ng-container>

	<ng-container *ngIf="data?.methods">
		<div class="doc-container-title">Important Methods</div>
		<div *ngFor="let method of data.methods; last as lastMethod" [class.mb-2]="!lastMethod">
			<app-code-display [code]="method.code" [methodName]="method.name" [useExpansionPanel]="true"></app-code-display>
		</div>
	</ng-container>
</div>
