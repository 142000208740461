import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
	selector: 'app-no-page',
	templateUrl: './no-page.component.html',
	styleUrls: ['./no-page.component.scss'],
})
export class NoPageComponent {
	constructor(public location: Location, public router: Router) {}
}
