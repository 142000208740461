import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentationInterface } from '../../../models/dev/doc-interface.model';
import { DocumentationService } from '../../../services/documentation.service';

@Component({
	selector: 'app-documentation-model',
	templateUrl: './documentation-model.component.html',
	styleUrls: ['./documentation-model.component.scss']
})
export class DocumentationModelComponent implements OnInit {
	constructor(
		@Inject(MAT_DIALOG_DATA) public readonly data: DocumentationInterface,
		public readonly documentationService: DocumentationService
	) {}

	ngOnInit(): void {}
}
