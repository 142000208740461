import {
	DocumentationFlow,
	DocumentationFlowSectionFormatTypeEnum,
	DocumentationFlowSectionTypeEnum,
} from '../../models/dev/doc-overview.model';
import { DocumentationFlowTypeEnum } from '../../models/dev/doc-types.enum';

export const documentationReportingDashboardFlow: DocumentationFlow = {
	title: 'Reporting Dashboard',
	description:
		'The Reporting Dashboard is a comprehensive tool that provides a high-level overview of the application’s data. It includes multiple sections, each displaying specific types of data in an organized format.',
	sections: [
		{
			type: DocumentationFlowSectionTypeEnum.MAIN_TITLE,
			text: 'Reporting Settings',
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'The main file for configuring the entire reporting page is default-reporting-config-map.data.ts. This file defines the structure for all reporting dashboards.',
			formats: [
				{
					textIdentifier: 'default-reporting-config-map.data.ts',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
			],
		},
		{
			type: DocumentationFlowSectionTypeEnum.SUBTITLE,
			text: 'New Reporting Page',
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'To create a new page, you need to define at least the id, name, icon, and an array of pages. All available options are specified in the CustomDashboardPage interface, located in graph.model.ts.',
			formats: [
				{
					textIdentifier: 'graph.model.ts',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
				{
					textIdentifier: 'CustomDashboardPage',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
			],
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'The formatting and structure of the reporting page are handled by GraphCustomDashboardContainerComponent (app-graph-custom-dashboard-container.component.html).',
			formats: [
				{
					textIdentifier: 'GraphCustomDashboardContainerComponent',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					styling: ['italic'],
				},
				{
					textIdentifier: 'app-graph-custom-dashboard-container.component.html',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
			],
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: `GraphCustomDashboardContainerComponent manages different graph types. If you wish to add a new graph type, you can follow the steps outlined in the ${DocumentationFlowTypeEnum.NEW_GRAPH} flow.`,
			formats: [
				{
					textIdentifier: DocumentationFlowTypeEnum.NEW_GRAPH,
					type: DocumentationFlowSectionFormatTypeEnum.FLOW,
				},
			],
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'If the existing charts, tables, or custom components do not meet your needs, you can create a custom table or chart by building a new component within the graph folder and importing it into graph.module.ts. Next, integrate the new component into GraphCustomDashboardContainerComponent based on the page id. For instance:',
			formats: [
				{
					textIdentifier: 'graph.module.ts',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
			],
		},
		{
			type: DocumentationFlowSectionTypeEnum.CODE_SNIPPET,
			text: `
  <div *ngIf="page?.id === 'compliance-tracker'">
	  <app-admin-compliance-tracker [filterForm]="filterForm" [page]="page" [settings]="settings">
	  </app-admin-compliance-tracker>
  </div>
		  `,
		},
		{
			type: DocumentationFlowSectionTypeEnum.MAIN_TITLE,
			text: 'Investment Disbursement',
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'InvestmentDisbursementComponent serves as the main component for most charts and tables on the reporting page. Located in investment-disbursement.component.ts, it formats the data and sends it to GraphBaseComponent (app-graph-base.component.ts), which then displays the data.',
			formats: [
				{
					textIdentifier: 'InvestmentDisbursementComponent',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
				{
					textIdentifier: 'investment-disbursement.component.ts',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
				{
					textIdentifier: 'app-graph-base.component.ts',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
			],
		},
	],
};
