import { DocumentationQABasic } from '../../../models/qa/doc-qa-basic.model';
import { DocumentationQASectionTypeEnum } from '../../../models/qa/doc-qa-types.model';

export const documentationProgramSpendDashboardQA: DocumentationQABasic = {
	title: 'Program Spend Dashboard',
	description:
		'The Program Spend Dashboard provides a summary of Tactic Costs for all tactics within programs in the "activate" phase. It enables clients to view their budgets broken down by key segments, including brand, retailer, funding source, phase, initiative, category, and more.',
	sections: [
		{
			type: DocumentationQASectionTypeEnum.MAIN_TITLE,
			text: 'Dashboard Overview',
		},
		{
			type: DocumentationQASectionTypeEnum.BULLETED_LIST,
			text: '',
			bulletedList: [
				'The dashboard begins with broad, high-level widgets, which become more detailed as you navigate down.',
				'Spend is use synonymously with Tactic Cost',
			],
			formats: [
				{
					textIdentifier: 'Spend',
					styling: ['bold'],
				},
				{
					textIdentifier: 'Tactic Cost',
					styling: ['bold'],
				},
			],
		},
		{
			type: DocumentationQASectionTypeEnum.MAIN_TITLE,
			text: 'Dashboard Widgets',
		},
		{
			type: DocumentationQASectionTypeEnum.SUBTITLE,
			text: 'First Bar of Indicator Widgets',
		},
		{
			type: DocumentationQASectionTypeEnum.BULLETED_LIST,
			text: 'This is the broadest client data, providing a top-line summary of Spend information:',
			bulletedList: [
				'# of Tactics: Counts the number of tactics within that budget year.',
				'Total Budget Allocation: Displays the total amount allocated to the budget tab for all applicable programs.',
				'Total Estimated Spend: Shows the sum of estimated spend on the Tactic Cost tab for all tactics across relevant programs.',
				'Total Actual Spend: The amount on the invoice tab across all tactics in relevant programs, regardless of invoice status.',
				'Total Invoices Paid: Displays the total amount of paid invoices across all relevant tactics.',
			],
			formats: [
				{
					textIdentifier: '# of Tactics',
					styling: ['bold'],
				},
				{
					textIdentifier: 'Total Budget Allocation',
					styling: ['bold'],
				},
				{
					textIdentifier: 'Total Estimated Spend',
					styling: ['bold'],
				},
				{
					textIdentifier: 'Total Actual Spend',
					styling: ['bold'],
				},
				{
					textIdentifier: 'Total Invoices Paid',
					styling: ['bold'],
				},
			],
		},
		{
			type: DocumentationQASectionTypeEnum.SUBTITLE,
			text: 'Budget Comparison',
		},
		{
			type: DocumentationQASectionTypeEnum.BULLETED_LIST,
			text: '',
			bulletedList: [
				'This defaults to a bar chart, showing Allocated Budget, Estimated Spend and Invoices Paid by Brand.',
				'In the top right, you can adjust the X axis to a variety of fields such as Retailer, Agency, Program Sector, Program Phase, Brand Initiative, Tags, Macro Objectives, etc.',
				'You can toggle between bar chart and table',
			],
		},
		{
			type: DocumentationQASectionTypeEnum.SUBTITLE,
			text: 'Spend by Tactical Focus',
		},
		{
			type: DocumentationQASectionTypeEnum.BULLETED_LIST,
			text: '',
			bulletedList: [
				'This section provides a pie chart and table view of Tactic Cost (Spend) by Tactical Focus.',
				'Each Tactic type is mapped to 1 of 3 tactical focuses: Search, Shopper or Non-Working.',
				'If there is a tactic type displayed in this section, it means it is missing from the Excel Mapping file that is on the back end. Simply add it to the excel with the correct Tactical Focus and re-ingest.',
			],
		},
		{
			type: DocumentationQASectionTypeEnum.SUBTITLE,
			text: 'Spend By Tactic Category',
		},

		{
			type: DocumentationQASectionTypeEnum.BULLETED_LIST,
			text: '',
			bulletedList: ['This section provides a pie chart and table view of Tactic Cost (Spend) by Tactic Category.'],
		},
	],
	directions: [
		{
			title: 'Create a New Budget Period',
			completed: false,
			items: [
				{
					title: 'Begin by setting up a new budget period in the system.',
					completed: false,
				},
			],
		},
		{
			title: 'Create a Multi-Brand Program',
			completed: false,
			items: [
				{
					title: 'Details Tab',
					completed: false,
					items: [
						{
							title: 'Complete the fields for Brands (using two brands) and Retailers.',
							completed: false,
						},
					],
				},
				{
					title: 'Budget Tab',
					completed: false,
					items: [
						{
							title: 'Create two separate budget items:',
							completed: false,
							items: [
								{
									title: 'One budget item with both brands, including Funding Source, Funding Type, and Budget.',
									completed: false,
								},
								{
									title: 'A second budget item with only one brand, including Funding Source, Funding Type, and Budget.',
									completed: false,
								},
							],
						},
					],
				},
				{
					title: 'Tactic Tab',
					completed: false,
					items: [
						{
							title: 'Create three tactics:',
							completed: false,
							items: [
								{
									title: 'Tactic 1: Includes both brands participating.',
									completed: false,
								},
								{
									title: 'Tactic 2: Includes only one brand participating.',
									completed: false,
								},
								{
									title: 'Tactic 3: Includes the other brand participating.',
									completed: false,
								},
								{
									title: 'Ensure each tactic’s type, cost, and cost type are completed.',
									completed: false,
								},
								{
									title: 'Ensure that the total for tactics differs from the total for the budget to verify that the dashboard uses the correct budget amounts rather than the tactic amounts.',
									completed: false,
								},
							],
						},
					],
				},
			],
		},
		{
			title: 'Refresh and Check the Dashboard',
			completed: false,
			items: [
				{
					title: 'Refresh the page, then navigate to the Program Spend Dashboard and filter for the newly created budget period.',
					completed: false,
				},
			],
		},
		{
			title: 'QA Checklist',
			completed: false,
			items: [
				{
					title: 'Confirm the top bar of widgets match what was entered in the single program.',
					completed: false,
				},
				{
					title: 'If a widget displays double the budget or tactic amount or the amount from the first budget item, check that the dashboard is using the correct data (budget amount vs. brand breakout).',
					completed: false,
				},
				{
					title: 'Confirm the other widgets display the correct Tactic Spend and Tactic Counts. If any spend is double, it is because of the multi-brand.',
					completed: false,
				},
			],
		},
		{
			title: 'Create Additional Programs',
			completed: false,
			items: [
				{
					title: 'Return to the activate tab and create two additional programs, each with different retailers and tactic types (preferably in different categories).',
					completed: false,
				},
				{
					title: 'You can duplicate the original program, but modify the tactic type, for each new program. Also, change the tactic spend on each.',
					completed: false,
				},
				{
					title: 'Create one more program with only one brand with at least one tactic and tactic cost.',
					completed: false,
				},
			],
		},
		{
			title: 'Recheck the Dashboard',
			completed: false,
			items: [
				{
					title: 'After making changes, verify that the dashboard updates accurately by comparing the Estimated Tactic Spend column in the table view to the totals displayed in the dashboard for each widget.',
					completed: false,
				},
				{
					title: 'Change the budget filter and retest.',
					completed: false,
				},
			],
		},
	],
};
