import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { DocumentationService } from '../../../services/documentation.service';
import { DocumentationFlowSectionFormat, DocumentationFlowSectionFormatTypeEnum } from '../../../models/dev/doc-overview.model';
import { DocumentationTypeOption } from '../../../models/dev/doc-types.enum';
import { DocumentationQASectionFormat } from '../../../models/qa/doc-qa-types.model';

@Component({
	selector: 'app-documentation-dynamic-type',
	templateUrl: './dynamic-type.component.html',
	styleUrls: ['./dynamic-type.component.scss'],
})
export class DocumentationDynamicTypeComponent implements OnInit {
	@Input() text: string;
	@Input() formats?: Array<DocumentationFlowSectionFormat | DocumentationQASectionFormat>;
	@Input() qaType?: boolean;

	highlightedText: SafeHtml;

	constructor(
		private readonly sanitizer: DomSanitizer,
		private readonly renderer: Renderer2,
		private readonly dialog: MatDialog,
		private readonly documentationService: DocumentationService
	) {}

	ngOnInit(): void {
		this.highlightAndMarkIdentifier();
	}

	highlightAndMarkIdentifier(): void {
		let html = this.text;
		if (this.formats?.length) {
			this.formats.forEach((format) => {
				const identifier = format.textIdentifier;
				const escapedIdentifier = identifier.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters
				const regExp = new RegExp(`(^|\\s|\\W)(${escapedIdentifier})(?=$|\\s|\\W)`, 'g'); // Custom boundary match

				const span = this.renderer.createElement('span');

				if (!this.qaType) {
					if ((format as DocumentationFlowSectionFormat).type === DocumentationFlowSectionFormatTypeEnum.INTERFACE) {
						this.renderer.addClass(span, 'doc-clickable-interface');
					} else if ((format as DocumentationFlowSectionFormat).type === DocumentationFlowSectionFormatTypeEnum.FLOW) {
						this.renderer.addClass(span, 'doc-clickable-flow');
					} else if ((format as DocumentationFlowSectionFormat).type === DocumentationFlowSectionFormatTypeEnum.COMPONENT) {
						this.renderer.addClass(span, 'doc-clickable-component');
					}

					if ((format as DocumentationFlowSectionFormat).type !== DocumentationFlowSectionFormatTypeEnum.TEXT) {
						this.renderer.setAttribute(span, 'data-identifier', identifier);
					}

					this.renderer.setAttribute(span, 'data-identifier-type', (format as DocumentationFlowSectionFormat).type);
				}

				this.renderer.appendChild(span, this.renderer.createText(identifier));

				// Add Styles
				format.styling?.forEach((style) => {
					if (style === 'bold') {
						this.renderer.setStyle(span, 'font-weight', 'bold');
					}
					if (style === 'italic') {
						this.renderer.setStyle(span, 'font-style', 'italic');
					}
				});

				// Add Classes
				format.classes?.forEach((className) => {
					this.renderer.addClass(span, `documentation-${className}`);

					const applyPath = ['file-location'].includes(className);
					if (applyPath) {
						this.renderer.setAttribute(span, 'data-path', identifier);
					}
				});

				// Replace the text with the span element
				html = html.replace(regExp, `$1${span.outerHTML}`);
			});
		}
		this.highlightedText = this.sanitizer.bypassSecurityTrustHtml(html);
	}

	onClick(event: MouseEvent): void {
		const target = event.target as HTMLElement;
		const identifier = target.getAttribute('data-identifier');
		const identifierType = target.getAttribute('data-identifier-type') as DocumentationFlowSectionFormatTypeEnum;

		console.log('Clicked on:', identifier, identifierType);

		if (identifier && identifierType) {
			this.documentationService.openDataDialog(identifier as DocumentationTypeOption, identifierType);
		}

		// Check if the clicked element has a path attribute
		const path = target.getAttribute('data-path');
		if (path) {
			this.documentationService.copyTextToClipboard(path);
		}
	}
}
