import { DocumentationComponent } from '../../models/dev/doc-component.model';
import { DocumentationFlowSectionFormatTypeEnum } from '../../models/dev/doc-overview.model';
import { DocumentationComponentTypeEnum, DocumentationFlowTypeEnum, DocumentationInterfaceTypeEnum } from '../../models/dev/doc-types.enum';

export const documentationAdminSettingsComponent: DocumentationComponent = {
	title: 'AdminComponent',
	path: 'web/src/app/pages/admin/admin.component.ts',
	description: 'This component is used to manage the settings of the application',
	inputs: {
		items: [
			{
				name: 'UserRole',
				type: 'UserRole',
				comment: 'The role of the user'
			},

			{
				name: 'filter',
				type: DocumentationInterfaceTypeEnum.FILTER
			}
		],
		formats: [
			{
				textIdentifier: DocumentationInterfaceTypeEnum.FILTER,
				type: DocumentationFlowSectionFormatTypeEnum.INTERFACE
			}
		]
	},
	outputs: {
		items: [
			{
				name: 'changeFilterParameters',
				type: 'EventEmitter<FilterParameters>',
				value: 'new EventEmitter()',
				comment: 'Emits the filter parameters'
			}
		]
	},
	properties: {
		items: [
			{
				name: 'private readonly unsubscribe$',
				type: 'Subject<void>',
				value: 'new Subject<void>()',
				comment: 'The unsubscribe subject which is used to unsubscribe from all subscriptions'
			}
		]
	},
	flows: [DocumentationFlowTypeEnum.FILTERS],
	components: [DocumentationComponentTypeEnum.ADMIN_COMPONENT],
	methods: [
		{
			name: 'ngOnInit()',
			code: `
this.sessionService.getUserStatus(this.sessionQuery.getToken()).subscribe(resp => {
	this.role = resp.profile?.role;
	if (resp.profile?.role !== 'admin' && resp.profile?.role !== 'super-admin') {
		this.router.navigate(['/']);
	}
});
			`
		},
		{
			name: 'selectedCategoryChange(tag: string)',
			code: `
let url = '/admin';
let index = this.adminCategories.findIndex(cat => cat.tag === tag); // this.adminCategories is an array of objects with a tag property

this.location.replaceState(url/this.adminCategories[index]?.tag);
this.selectedCategory = index;
			`
		}
	]
};
