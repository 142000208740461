import { DocumentationQABasic } from '../../../models/qa/doc-qa-basic.model';
import { DocumentationQASectionTypeEnum } from '../../../models/qa/doc-qa-types.model';

export const documentationRMNvsNonRMNDashboardQA: DocumentationQABasic = {
	title: 'RMN vs Non RMN Dashboard',
	description:
		'The RMN vs Non RMN dashboard automatically summarizes tactic spend based on total, RMN or Non-RMN. This helps clients see how much of their budget they are spending on RMN tactics by brand, retailer, vendor, and tactic category',
	sections: [
		{
			type: DocumentationQASectionTypeEnum.MAIN_TITLE,
			text: 'Dashboard Overview',
		},
		{
			type: DocumentationQASectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'The dashboard begins with broad, high-level widgets, which become more detailed as you navigate down.',
		},

		{
			type: DocumentationQASectionTypeEnum.MAIN_TITLE,
			text: 'Dashboard Widgets',
		},
		{
			type: DocumentationQASectionTypeEnum.SUBTITLE,
			text: 'Spend Summary',
		},
		{
			type: DocumentationQASectionTypeEnum.BULLETED_LIST,
			text: '',
			bulletedList: [
				'Total Estimated Spend: Shows the sum of estimated spend on the Tactic Cost tab for all tactics across relevant programs.',
				'Total Actual Spend: The amount on the invoice tab across all tactics in relevant programs, regardless of invoice status.',
				'Total Invoices Paid: Displays the total amount of paid invoices across all relevant tactics.',
				'% Spend on RMN: Total RMN Spend / Total Spend',
				'% of Tactics RMN: Number of tactics labeled RMN / Total number of tactics',
				'Total RMN vs Non-RMN Spend: Bar chart showing the number of RMN and Non-RMN tactics by Brand. Can be switched to table view.',
				'YOY Total RMN vs Non-RMN Spend: Bar chart and table view that shows each year’s RMN vs Non-RMN Spend, Total Spend, and % Spend on RMN',
			],
			formats: [
				{
					textIdentifier: 'Total Estimated Spend',
					styling: ['bold'],
				},
				{
					textIdentifier: 'Total Actual Spend',
					styling: ['bold'],
				},
				{
					textIdentifier: 'Total Invoices Paid',
					styling: ['bold'],
				},
				{
					textIdentifier: '% Spend on RMN',
					styling: ['bold'],
				},
				{
					textIdentifier: '% of Tactics RMN',
					styling: ['bold'],
				},
				{
					textIdentifier: 'Total RMN vs Non-RMN Spend',
					styling: ['bold'],
				},
				{
					textIdentifier: 'YOY Total RMN vs Non-RMN Spend',
					styling: ['bold'],
				},
			],
		},

		{
			type: DocumentationQASectionTypeEnum.SUBTITLE,
			text: 'By Retailer',
		},
		{
			type: DocumentationQASectionTypeEnum.BULLETED_LIST,
			text: 'This tab provides RMN vs Non-RMN Spend by Retailer. It includes:',
			bulletedList: [
				'A bar chart displaying RMN vs Non-RMN spend by retailer.',
				'A detailed table view showing total spend, RMN spend, Non-RMN spend, and % Spend on RMN for each retailer.',
			],
		},
		{
			type: DocumentationQASectionTypeEnum.SUBTITLE,
			text: 'By Brand',
		},
		{
			type: DocumentationQASectionTypeEnum.BULLETED_LIST,
			text: 'This tab provides RMN vs Non-RMN Spend by Brand. It includes:',
			bulletedList: [
				'A bar chart displaying RMN vs Non-RMN spend by brand.',
				'A detailed table view showing total spend, RMN spend, Non-RMN spend, and % Spend on RMN for each brand.',
			],
		},
		{
			type: DocumentationQASectionTypeEnum.SUBTITLE,
			text: 'By Vendor',
		},
		{
			type: DocumentationQASectionTypeEnum.BULLETED_LIST,
			text: 'This tab provides RMN vs Non-RMN Spend by Vendor. It includes:',
			bulletedList: [
				'A bar chart displaying RMN vs Non-RMN spend by vendor.',
				'A detailed table view showing total spend, RMN spend, Non-RMN spend, and % Spend on RMN for each vendor.',
				'A table view breaking down spend by tactic category and vendor, showing total spend, RMN spend, Non-RMN spend, and % Spend on RMN.',
			],
		},
		{
			type: DocumentationQASectionTypeEnum.SUBTITLE,
			text: 'By Tactic',
		},
		{
			type: DocumentationQASectionTypeEnum.BULLETED_LIST,
			text: 'This tab provides RMN vs Non-RMN Spend by Tactic Category. It includes:',
			bulletedList: [
				'A bar chart comparing RMN and Non-RMN spend by tactic category.',
				'A table showing each tactic category, total spend, RMN spend, Non-RMN spend, % Spend on RMN, and a count of unique vendors.',
				'A table detailing each tactic type with total spend, RMN spend, Non-RMN spend, % Spend on RMN, and unique vendor counts.',
				'A final table showing raw data, with each tactic detailed by program name, budget year, retailer, brand, program phase, tactic type, start and end date, vendor, tactic phase, RMN status, and total spend.',
			],
		},
	],
	directions: [
		{
			title: 'Create a New Budget Period',
			completed: false,
		},
		{
			title: 'Create a Multi-Brand Program',
			completed: false,
			items: [
				{
					title: 'Details Tab',
					completed: false,
					items: [
						{
							title: 'Complete the fields for Brands (using two brands) and Retailers.',
							completed: false,
						},
					],
				},
				{
					title: 'Tactic Tab',
					completed: false,
					items: [
						{
							title: 'Create three tactics:',
							completed: false,
							items: [
								{
									title: 'Tactic 1: Includes both brands participating. Mark this as RMN and select a vendor.',
									completed: false,
								},
								{
									title: 'Tactic 2: Includes only one brand participating. Mark this as RMN and select a vendor.',
									completed: false,
								},
								{
									title: 'Tactic 3: Includes the other brand participating. Mark this as RMN and select a vendor.',
									completed: false,
								},
								{
									title: 'Ensure each tactic’s type, cost, and cost type are completed. Ensure the total for tactics differs from each tactic for easy QA.',
									completed: false,
								},
							],
						},
					],
				},
			],
		},
		{
			title: 'Refresh and Check the Dashboard',
			completed: false,
			items: [
				{
					title: 'Refresh the page, then navigate to the RMN vs Non-RMN Dashboard and filter for the newly created budget period.',
					completed: false,
				},
			],
		},
		{
			title: 'QA Checklist',
			completed: false,
			items: [
				{
					title: 'Confirm the top bar of widgets match what was entered in the single program.',
					completed: false,
				},
				{
					title: 'If a widget displays double or more than the tactic cost, check that the dashboard is using the correct data. If the total is over by the amount of the first tactic, this means that the dashboard is counting the tactic cost in total for each brand vs splitting it out.',
					completed: false,
				},
				{
					title: 'Confirm the other widgets display the correct Tactic Spend and Tactic Counts. If any spend is double, it is because of the multi-brand.',
					completed: false,
				},
				{
					title: 'Ensure the other widgets are properly identifying which tactics are RMN vs which are Non-RMN.',
					completed: false,
				},
			],
		},
		{
			title: 'Create Additional Programs',
			completed: false,
			items: [
				{
					title: 'Return to the activate tab and create two additional programs, each with different retailers and tactic types (preferably in different categories).',
					completed: false,
				},
				{
					title: 'You can duplicate the original program, but modify the tactic type, for each new program. Also, change the tactic spend on each.',
					completed: false,
				},
				{
					title: 'Create one more program with only one brand with at least one tactic and tactic cost.',
					completed: false,
				},
			],
		},
		{
			title: 'Recheck the Dashboard',
			completed: false,
			items: [
				{
					title: 'After making changes, verify that the dashboard updates accurately by comparing the Estimated Tactic Spend column in the table view to the totals displayed in the dashboard for each widget.',
					completed: false,
				},
				{
					title: 'Change the budget filter and retest.',
					completed: false,
				},
			],
		},
		{
			title: 'Check the Dashboard for Multiple Budget Years',
			completed: false,
			items: [
				{
					title: 'Create a new budget year.',
					completed: false,
				},
				{
					title: 'Add a Program with multiple tactics. Ensure the tactics have tactic cost, RMN status, and vendor complete.',
					completed: false,
				},
			],
		},
		{
			title: 'Recheck the Dashboard',
			completed: false,
			items: [
				{
					title: 'After adding a second budget year, verify that the dashboard updates accurately by filtering to 2 budget periods. Check each widget to ensure they work properly.',
					completed: false,
				},
				{
					title: 'Check the remaining filters.',
					completed: false,
				},
			],
		},
	],
};
