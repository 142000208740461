import {
	DocumentationFlow,
	DocumentationFlowSectionFormatTypeEnum,
	DocumentationFlowSectionTypeEnum,
} from '../../models/dev/doc-overview.model';
import { DocumentationComponentTypeEnum } from '../../models/dev/doc-types.enum';

export const documentationColumnOverview: DocumentationFlow = {
	title: 'Column System',
	description: `The column system provides a flexible way to customize the display and behavior of data in tables and calendars. It allows users to select which columns they want to see and how they want the information presented. The column are commonly added to the tables and calendars, through the popup confirm dialogs, and also through the individual dropdowns in some components.
				\n The definition of the column interface with the description of available options can be found in web/src/app/state/table/table.model.ts`,
	formats: [
		{
			textIdentifier: 'web/src/app/state/table/table.model.ts',
			type: DocumentationFlowSectionFormatTypeEnum.TEXT,
			classes: ['file-location'],
		},
	],
	sections: [
		{
			type: DocumentationFlowSectionTypeEnum.MAIN_TITLE,
			text: 'Key Concepts',
		},
		{
			type: DocumentationFlowSectionTypeEnum.TITLE,
			text: 'Column',
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: `A column represents a vertical row of information in a table or a calendar. It not only defines what data to display but also how to display it, including UI settings and export options. Definition and structure for the columns is provided in table.model.ts. Getting columns, active columns and column collections can be found in web/src/app/state/table/table.query.ts.
				\n Active columns represent columns, that are currently added to the table or calendar.`,
			formats: [
				{
					textIdentifier: 'table.model.ts',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
				{
					textIdentifier: 'web/src/app/state/table/table.query.ts',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
			],
		},
		{
			type: DocumentationFlowSectionTypeEnum.TITLE,
			text: 'Dynamic Column',
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: `The system supports dynamic columns, which are generated based on settings or options from the API. This allows for flexible and customizable column configurations that can adapt to changing requirements or user preferences.`,
		},
		{
			type: DocumentationFlowSectionTypeEnum.TITLE,
			text: 'Column Collection',
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: `A column collection is a group of columns that can be applied to a specific view, such as a table or calendar. It defines the overall structure and available options for data presentation. We have different types of column collections defined:`,
		},
		{
			type: DocumentationFlowSectionTypeEnum.SUBTITLE,
			text: `1. Overall column collection`,
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: `Columns that apply to all entities in table views. These columns are displayed, no matter the the section we are currently at. Aside from column display, these definitions are also used across diferent sections for display, such as Reporting view and dashboards.
				\n The definition of overall columns can be found in web/src/app/state/global/global.model.ts`,
			formats: [
				{
					textIdentifier: 'web/src/app/state/global/global.model.ts',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
			],
		},
		{
			type: DocumentationFlowSectionTypeEnum.SUBTITLE,
			text: `2. Dynamic column collection`,
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: `Columns that can also be applied to all entities in table views. These columns are have special funcionality, they enable the definition for different options for selection, which are grouped under dynamic column.
				\n The definition of dynamic columns can also be found in web/src/app/state/global/global.model.ts
				\n Currently, there are 2 dynamic columns:`,
			formats: [
				{
					textIdentifier: 'web/src/app/state/global/global.model.ts',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
			],
		},
		{
			type: DocumentationFlowSectionTypeEnum.SUBTITLE,
			text: `External Ids`,
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: `External ids are definitions that reference an entity in another system. Each organization can create their own id types. External IDs are grouped by type which is a customized field from the CMS. The definition can be found in web/src/app/state/entities/external-id/external-id.model.ts`,
			formats: [
				{
					textIdentifier: 'web/src/app/state/entities/external-id/external-id.model.ts',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
			],
		},
		{
			type: DocumentationFlowSectionTypeEnum.SUBTITLE,
			text: `Measurements`,
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: `A measurement definitions are a type and value that represent a performance metric of some kind for a tactic. (eg. Impressions, CPC, ROAS, etc.). Measurements are grouped by type. Types are provided by the settings endpoint and configured in the CMS. Each measurement type has a unit. Units describe how to mask the values provided (eg. %, $, etc.). The definition can be found in web/src/app/state/entities/measurement/measurement.model.ts`,
			formats: [
				{
					textIdentifier: 'web/src/app/state/entities/measurement/measurement.model.ts',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
				{
					textIdentifier: '(eg. Impressions, CPC, ROAS, etc.)',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					styling: ['italic'],
				},
				{
					textIdentifier: '(eg. %, $, etc.)',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					styling: ['italic'],
				},
			],
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: `In global.model.ts, we can also see, under overall columns, definition for selection of all dynamic items. These columns have ids 'all-external-ids' and 'all-measurements', and enable option to select all measurement or all external ids.`,
			formats: [
				{
					textIdentifier: 'global.model.ts',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
				{
					textIdentifier: "'all-external-ids'",
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
				{
					textIdentifier: "'all-measurements'",
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
			],
		},
		{
			type: DocumentationFlowSectionTypeEnum.SUBTITLE,
			text: `3. Entity column collection`,
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: `Entity columns are columns, which are specific for certain entity. These columns are unique, and show only on sections, that they are assigned to. Filters also have influence on the columns (eg. if we filter out programs in table, the program columns will also not show). There are 4 definition types:`,
			formats: [
				{
					textIdentifier: '(eg. if we filter out programs in table, the program columns will also not show)',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					styling: ['italic'],
				},
			],
		},
		{
			type: DocumentationFlowSectionTypeEnum.BULLETED_LIST,
			bulletedList: {
				li: [
					'Program column collection web/src/app/state/entities/program/program.model.ts',
					'Plan column collection web/src/app/state/entities/plan/plan.model.ts',
					'Tactic column collection web/src/app/state/entities/tactic/tactic.model.ts',
					'Invoice column collection web/src/app/state/entities/invoice/invoice.model.ts',
				],
			},
			formats: [
				{
					textIdentifier: 'web/src/app/state/entities/program/program.model.ts',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
				{
					textIdentifier: 'web/src/app/state/entities/plan/plan.model.ts',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
				{
					textIdentifier: 'web/src/app/state/entities/tactic/tactic.model.ts',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
				{
					textIdentifier: 'web/src/app/state/entities/invoice/invoice.model.ts',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
			],
		},
		{
			type: DocumentationFlowSectionTypeEnum.MAIN_TITLE,
			text: 'Column Dialog',
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: `The TableColumnDialogComponent is a crucial part of the column system, providing an interface for column selection and customization. Column dialog component can be found in web/src/app/state/table/table-column-dialog/table-column-dialog.component.ts`,
			formats: [
				{
					textIdentifier: 'web/src/app/state/table/table-column-dialog/table-column-dialog.component.ts',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
				{
					textIdentifier: DocumentationComponentTypeEnum.TABLE_COLUMN_DIALOG,
					type: DocumentationFlowSectionFormatTypeEnum.COMPONENT,
				},
			],
		},
		{
			type: DocumentationFlowSectionTypeEnum.BULLETED_LIST,
			text: 'Key features',
			bulletedList: {
				li: [
					'Column Selection: Users can choose which columns to display by checking or unchecking individual columns.',
					'Category Filtering: Columns are grouped by categories, and users can filter columns by these categories for easier navigation. We can filter through different entity options that are available in the current section.',
					'Search Functionality: A search bar allows users to quickly find specific columns.',
					'Bulk Selection: "Select All" options for each category enable quick selection of multiple columns.',
					'Measurement Type Toggle: For measurement columns, users can toggle between actual and benchmark data types. The toggles work in a way, that force user to always have 1 or 2 selections available. If both toggles are on, both measurement options will be added to the tables.',
					'Column Limit Enforcement: The dialog enforces any configured maximum limit on the number of active columns.',
				],
			},
			formats: [
				{
					textIdentifier: 'Column Selection:',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					styling: ['bold'],
				},
				{
					textIdentifier: 'Category Filtering:',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					styling: ['bold'],
				},
				{
					textIdentifier: 'Search Functionality:',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					styling: ['bold'],
				},
				{
					textIdentifier: 'Bulk Selection:',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					styling: ['bold'],
				},
				{
					textIdentifier: 'Measurement Type Toggle:',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					styling: ['bold'],
				},
				{
					textIdentifier: 'Column Limit Enforcement:',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					styling: ['bold'],
				},
			],
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: `Usage of this component can be found in function openColumnDialog(), which can be found in web/src/app/state/table/table-container/table-container.component.ts for the table, and web/src/app/state/table/table-calendar-basic/table-calendar-basic.component.ts for the calendar.`,
			formats: [
				{
					textIdentifier: 'openColumnDialog()',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
				{
					textIdentifier: 'web/src/app/state/table/table-container/table-container.component.ts',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
				{
					textIdentifier: 'web/src/app/state/table/table-calendar-basic/table-calendar-basic.component.ts',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
			],
		},
		{
			type: DocumentationFlowSectionTypeEnum.MAIN_TITLE,
			text: 'Sticky columns',
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: `Sticky columns are columns, that are being transfered between chaning views. Each view and entity have a default columns, which are also defined in models for entity column collection. When user navigates to different section, the sticky columns are cleared (eg. user goes to activate tab from plan tab).`,
			formats: [
				{
					textIdentifier: '(eg. user goes to activate tab from plan tab)',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					styling: ['italic'],
				},
			],
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: `Active column names can be found in TableSettings and CalendarSettings in table.model.ts.`,
			formats: [
				{
					textIdentifier: 'TableSettings',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
				{
					textIdentifier: 'CalendarSettings',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
				{
					textIdentifier: 'table.model.ts',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
			],
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: `Columns, that are selected from column, can be found in variable selectedColumnsFromDialog, which is found in web/src/app/state/table/table.service.ts.`,
			formats: [
				{
					textIdentifier: 'selectedColumnsFromDialog',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
				{
					textIdentifier: 'web/src/app/state/table/table.service.ts',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
			],
		},
		{
			type: DocumentationFlowSectionTypeEnum.DEFAULT_PARAGRAPH,
			text: `The Name column is usually sticky column, that should always be present in calendar or table. It also provider deepLink for the entity, that the column is representing.`,
			formats: [
				{
					textIdentifier: 'Name',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					classes: ['file-location'],
				},
			],
		},
		{
			type: DocumentationFlowSectionTypeEnum.MAIN_TITLE,
			text: 'Core Functionalities',
		},
		{
			type: DocumentationFlowSectionTypeEnum.BULLETED_LIST,
			bulletedList: {
				li: [
					'Customizable Display: Users can select which columns to display, allowing for personalized views of data.',
					'Data Mapping: Columns can map to specific data paths, ensuring the correct information is displayed.',
					'Formatting and Styling: Various options for formatting and styling data within columns, including custom buttons and icons.',
					'Sorting and Filtering: Columns can be configured for sorting and filtering, enhancing data exploration capabilities.',
					'Editability: Certain columns can be made editable, with various input types and validation options.',
					'Conditional Visibility: Columns can be shown or hidden based on specific conditions, allowing for context-sensitive displays.',
					'Aggregation: Support for data aggregation within columns for summary views.',
					'Export Customization: Separate paths can be defined for data export, allowing for different representations in the UI and exported data.',
				],
			},
			formats: [
				{
					textIdentifier: 'Customizable Display:',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					styling: ['bold'],
				},
				{
					textIdentifier: 'Data Mapping:',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					styling: ['bold'],
				},
				{
					textIdentifier: 'Formatting and Styling:',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					styling: ['bold'],
				},
				{
					textIdentifier: 'Sorting and Filtering:',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					styling: ['bold'],
				},
				{
					textIdentifier: 'Editability:',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					styling: ['bold'],
				},
				{
					textIdentifier: 'Conditional Visibility:',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					styling: ['bold'],
				},
				{
					textIdentifier: 'Aggregation:',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					styling: ['bold'],
				},
				{
					textIdentifier: 'Export Customization:',
					type: DocumentationFlowSectionFormatTypeEnum.TEXT,
					styling: ['bold'],
				},
			],
		},
	],
};
