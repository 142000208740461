<div mat-dialog-title fxLayout="row">
	<div fxLayout="column">
		<h3>QA Directions</h3>
	</div>
	<div class="actions margin-left-auto">
		<button mat-flat-button color="tertiary" class="button-extra-small mr-3" (click)="onReset()">Reset</button>
		<button mat-flat-button color="secondary" class="button-extra-small" [mat-dialog-close]="false">Close</button>
	</div>
</div>

<!-- Progress Bar at the Top -->
<div class="progress-bar-container">
	<div fxLayoutAlign="center" class="full-width mb-1">
		<span class="font-size-medium">{{ progressValue | number: "1.0-0" }}%</span>
	</div>
	<mat-progress-bar mode="determinate" [value]="progressValue"></mat-progress-bar>
</div>

<!-- Checklist -->
<div class="checklist">
	<ul>
		<ng-container *ngFor="let item of directions">
			<ng-template [ngTemplateOutlet]="checklistItemTemplate" [ngTemplateOutletContext]="{ item: item, level: 1 }"></ng-template>
		</ng-container>
	</ul>
</div>

<!-- Checklist Item Template -->
<ng-template #checklistItemTemplate let-item="item" let-level="level">
	<li>
		<mat-checkbox [(ngModel)]="item.completed" (change)="onCheckboxChange(item)">
			<span [ngClass]="item?.items?.length || level === 1 ? 'qa-directions-level-' + level : null">{{ item.title }}</span>
		</mat-checkbox>
		<ul *ngIf="item?.items?.length">
			<ng-container *ngFor="let child of item.items">
				<ng-template
					[ngTemplateOutlet]="checklistItemTemplate"
					[ngTemplateOutletContext]="{ item: child, level: level + 1 }"
				></ng-template>
			</ng-container>
		</ul>
	</li>
</ng-template>
