import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentationQABasic } from '../../../models/qa/doc-qa-basic.model';
import { DocumentationQASectionTypeEnum } from '../../../models/qa/doc-qa-types.model';
import { DocumentationService } from '../../../services/documentation.service';

@Component({
	selector: 'app-documentation-qa-overview',
	templateUrl: './qa-overview.component.html',
	styleUrls: ['./qa-overview.component.scss'],
})
export class DocumentationQAOverviewComponent implements OnInit {
	data: DocumentationQABasic;
	public readonly DocumentationQASectionTypeEnum = DocumentationQASectionTypeEnum;

	constructor(
		@Inject(MAT_DIALOG_DATA) public dialogData: DocumentationQABasic,
		private readonly documentationService: DocumentationService
	) {}

	ngOnInit(): void {
		this.data = this.dialogData;
	}

	openQADirections(): void {
		this.documentationService.openQADirectionsDialog(this.data.directions);
	}
}
