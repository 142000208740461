import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-documentation-search',
	templateUrl: './doc-search.component.html',
	styleUrls: ['./doc-search.component.scss'],
})
export class DocumentationSearchComponent implements OnInit {
	@Output() changed: EventEmitter<string> = new EventEmitter<string>();

	constructor() {}

	ngOnInit(): void {}

	model: string = '';

	onSearchChange(value: string): void {
		this.changed.emit(value);
	}
}
