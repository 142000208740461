import { Pipe, PipeTransform } from '@angular/core';

interface Item {
	title: string;
	description: string;
}

@Pipe({
	name: 'docSearchFilter',
})
export class DocumentationSearchFilterPipe implements PipeTransform {
	transform(items: Item[], searchTerm: string): Item[] {
		if (!items) return [];
		if (!searchTerm) return items;

		const lowerCaseTerm = searchTerm.toLowerCase();

		return items.filter(
			(item) => item.title.toLowerCase().includes(lowerCaseTerm) || item.description.toLowerCase().includes(lowerCaseTerm)
		);
	}
}
