<div mat-dialog-title fxLayout="row">
	<div class="doc-dialog-title-row">
		<h3>{{ data.title }}</h3>
		<i class="uil-copy" (click)="documentationService.copyTextToClipboard(data.path)"></i>
	</div>
	<div class="actions margin-left-auto">
		<button mat-flat-button color="secondary" class="button-extra-small" [mat-dialog-close]="false">Close</button>
	</div>
</div>

<div class="doc-light-container">
	<div class="doc-class-name">
		<app-documentation-dynamic-type [text]="data.exportLine" [formats]="data.formats"></app-documentation-dynamic-type>
	</div>

	<div class="doc-decorator" *ngFor="let decorator of data?.decorators || []">
		<span class="decorator-name">
			<app-documentation-dynamic-type [text]="decorator.name + ':'" [formats]="data.formats"></app-documentation-dynamic-type>
		</span>
		<span class="decorator-type">
			<app-documentation-dynamic-type [text]="decorator.type + ';'" [formats]="data.formats"></app-documentation-dynamic-type>
		</span>
	</div>
</div>
