import { DocumentationInterface } from '../../models/dev/doc-interface.model';
import { DocumentationFlowSectionFormatTypeEnum } from '../../models/dev/doc-overview.model';
import { DocumentationInterfaceTypeEnum } from '../../models/dev/doc-types.enum';

export const documentationInterfaceFilter: DocumentationInterface = {
	title: 'Filter',
	path: 'web/src/app/state/entities/filter/filter.model.ts',
	exportLine: 'export interface Filter',
	decorators: [
		{
			name: 'id',
			type: 'string'
		},
		{
			name: 'name',
			type: 'string'
		},
		{
			name: 'category',
			type: DocumentationInterfaceTypeEnum.FILTER_CATEGORY
		}
	],
	formats: [
		{
			textIdentifier: DocumentationInterfaceTypeEnum.FILTER_CATEGORY,
			type: DocumentationFlowSectionFormatTypeEnum.INTERFACE
		}
	]
};
